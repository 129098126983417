import { useQuery } from '@tanstack/react-query';

import { useCartId } from '@apple/features/cart/contexts/cart-id';

import { cartQueryOptions } from '../queries';

export function useCartItemCount() {
	const cartId = useCartId();
	return useQuery(cartQueryOptions.selectedQuantitiesCount({ cartId, enabled: !!cartId }));
}
