import { Alert, LoadingOverlay, Tabs } from '@mantine/core';

import { icons } from '@apple/assets';
import { useLocationAccess } from '@apple/features/location';
import { ChangePassword } from '@apple/features/user/components/ChangePassword';
import { ProfileForm } from '@apple/features/user/components/ProfileForm';
import { useTranslation } from '@apple/lib/i18next';
import type { Profile } from '@apple/features/auth-channel/models/user';
import type { ChangePasswordRequest, UpdateProfileRequest } from '@apple/features/auth/models/user';
import type {
	CountryOption,
	CurrencyOption,
	LanguageOption,
	TimeZoneOption,
} from '@apple/utils/globalization';

import { LocationAssociations } from './LocationAssociations';
import { ProgramAssociations } from './ProgramAssociations';

export type AccountSettingsProps = {
	profile: Profile;
	timeZones: TimeZoneOption[];
	languages: LanguageOption[];
	currencies: CurrencyOption[];
	countries: CountryOption[];
	updateProfile: (request: UpdateProfileRequest) => Promise<void>;
	changePassword: (request: ChangePasswordRequest) => Promise<void>;
	showSuccessMessage: boolean;
	setShowSuccessMessage: (state: boolean) => void;
};

export function AccountSettings({
	profile,
	countries,
	currencies,
	languages,
	timeZones,
	updateProfile,
	changePassword,
	showSuccessMessage,
	setShowSuccessMessage,
}: AccountSettingsProps) {
	const { t } = useTranslation('profile');
	const locationAccess = useLocationAccess(
		profile.hasProgramAssociations,
		profile.hasLocationAssociations,
	);

	if (locationAccess.isLoading) return <LoadingOverlay />;

	if (locationAccess.error)
		return (
			<Alert variant='light' color='red' title='Error' icon={<icons.Warning />}>
				{t('loadingError')}
			</Alert>
		);

	return (
		<Tabs defaultValue='profile'>
			<Tabs.List>
				<Tabs.Tab value='profile'>{t('tabs.userProfile')}</Tabs.Tab>
				<Tabs.Tab value='change-password'>{t('tabs.passwordReset')}</Tabs.Tab>
				{profile.hasProgramAssociations && (
					<Tabs.Tab value='programs-associations'>
						{t('tabs.programsAssociations')}
					</Tabs.Tab>
				)}
				{profile.hasLocationAssociations && (
					<Tabs.Tab value='locations-associations'>
						{t('tabs.locationsAssociations')}
					</Tabs.Tab>
				)}
			</Tabs.List>
			<Tabs.Panel value='profile'>
				<ProfileForm
					profile={profile}
					onSubmit={updateProfile}
					timeZones={timeZones}
					languages={languages}
					currencies={currencies}
					countries={countries}
					showSuccessMessage={showSuccessMessage}
					setShowSuccessMessage={setShowSuccessMessage}
				/>
			</Tabs.Panel>
			<Tabs.Panel value='change-password'>
				<ChangePassword onSubmit={changePassword} />
			</Tabs.Panel>
			{profile.hasProgramAssociations && (
				<Tabs.Panel value='programs-associations'>
					<ProgramAssociations associations={locationAccess.programs} />
				</Tabs.Panel>
			)}
			{profile.hasLocationAssociations && (
				<Tabs.Panel value='locations-associations' mt={20}>
					<LocationAssociations
						locationAccess={locationAccess.locations}
						deleteLocation={locationAccess.deleteLocation}
						requestLocationsApproval={locationAccess.requestLocationsApproval}
					/>
				</Tabs.Panel>
			)}
		</Tabs>
	);
}
