import { z } from 'zod';

import { productKeySchema } from '@apple/features/product/models/product';
import { countryCodeSchema, dateTimeSchema } from '@apple/utils/globalization';
import type { ProductKey } from '@apple/features/product/models/product';
import type { CountryCode } from '@apple/utils/globalization';

export interface ProductAssociation {
	itemId: ProductKey;
	isDeleted?: boolean;
	updatedDate?: string | Date;
}

export interface AllAssociation extends ProductAssociation {}

export interface PlantAssociation extends ProductAssociation {
	plantId: number;
}

export interface CountryAssociation extends ProductAssociation {
	countryCode: CountryCode;
}

export interface CustomerGroupAssociation extends ProductAssociation {
	customerGroupId: number;
}

export const associationSchema = z.object({
	itemId: productKeySchema,
	updatedDate: dateTimeSchema.optional(),
	isDeleted: z.boolean().optional(),
}) satisfies z.ZodType<ProductAssociation>;

export const allAssociationSchema = associationSchema satisfies z.ZodType<AllAssociation>;

export const plantAssociationSchema = associationSchema.extend({
	plantId: z.number(),
}) satisfies z.ZodType<PlantAssociation>;

export const countryAssociationSchema = associationSchema.extend({
	countryCode: countryCodeSchema,
}) satisfies z.ZodType<CountryAssociation>;
