import { z } from 'zod';

/**
 This file contains types and schemas for the authentication API.

 Constrollers:
 @see AppleBrandedPrograms.Web.Features.Profiles.AuthenticationController

 Models:
 @see Manzanita.Web.Models.SignInModel
 @see AppleBrandedPrograms.Web.Features.Profiles.AuthenticationController.LoginResponse
 @see AppleBrandedPrograms.Web.Features.Profiles.AuthenticationController.PasswordResetRequestModel

 Validation:
 @see Database.AppleRetail\SeedData\ValidationField.sql
 **/

export type LoginRequest = z.infer<typeof loginRequestSchema>;
export type LoginResponse = z.infer<typeof loginResponseSchema>;
export type LoginSuccessResponse = z.infer<typeof loginSuccessResponseSchema>;
export type LoginFailedResponse = z.infer<typeof loginFailedResponseSchema>;
export type LoginErrorCode = z.infer<typeof loginErrorCodeSchema>;
export type ForgotPasswordRequest = z.infer<typeof forgotPasswordRequestSchema>;

export const loginRequestSchema = z.object({
	userName: z.string(), //.min(1).max(70),
	// see Manzanita/Validators/PasswordValidator.cs
	// password: z.string().min(7).max(50),
	password: z.string(), //.min(8).max(35),
	rememberMe: z.boolean(),
	returnUrl: z.string(),
	termsAndConditionsContentId: z.number().optional().default(0),
});

export const loginSuccessResponseSchema = z.object({
	result: z.literal(true),
	redirectUrl: z.string(),
});

export const loginErrorCodeSchema = z.enum(['account_locked', 'invalid_login', 'no_access']);

export const loginFailedResponseSchema = z.object({
	result: z.literal(false),
	message: z.string(),
	errorCode: loginErrorCodeSchema,
});

export const loginResponseSchema = z.union([loginFailedResponseSchema, loginSuccessResponseSchema]);

export const forgotPasswordRequestSchema = z.object({
	username: z.string().min(1).max(70),
});

export function isLoginSuccessResponse(obj: unknown): obj is LoginSuccessResponse {
	return loginSuccessResponseSchema.safeParse(obj).success;
}

export function isLoginFailedResponse(obj: unknown): obj is LoginFailedResponse {
	return loginFailedResponseSchema.safeParse(obj).success;
}

export function isLoginErrorCode(obj: unknown): obj is LoginErrorCode {
	return loginErrorCodeSchema.safeParse(obj).success;
}
