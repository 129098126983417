import { z } from 'zod';

import { emptyOrNullableLocalDateTimeSchema } from '@apple/utils/globalization';

export interface Announcement {
	contentGroupId: string | null;
	name: string;
	rank: number;
	active: boolean;
	defaultContentId: number;
	defaultContent: string;
	countryCodes: string[] | null;
	customerCodes: string[] | null;
	localizations: AnnouncementLocalization[];
	createdDate?: '' | string | Date | null;
}

export interface AnnouncementLocalization {
	contentId: number;
	cultureCode: string;
	content: string;
}

export const announcementLocalizationSchema = z.object({
	contentId: z.number(),
	cultureCode: z.string(),
	content: z.string(),
}) satisfies z.ZodType<AnnouncementLocalization>;

export const announcementSchema = z.object({
	contentGroupId: z.string().nullable(),
	name: z.string().min(1),
	rank: z.number(),
	active: z.boolean(),
	defaultContentId: z.number(),
	defaultContent: z.string().min(1),
	countryCodes: z.array(z.string()).nullable(),
	customerCodes: z.array(z.string()).nullable(),
	localizations: z.array(announcementLocalizationSchema),
	createdDate: emptyOrNullableLocalDateTimeSchema.optional(),
}) satisfies z.ZodType<Announcement>;

export const announcementRequestSchema = announcementSchema
	.extend({
		content: announcementSchema.shape.defaultContent,
		localizedContent: announcementSchema.shape.localizations,
	})
	.omit({ defaultContent: true, localizations: true });

export type AnnouncementRequest = z.infer<typeof announcementRequestSchema>;
