import axios from 'axios';

import type { LocationId } from '../models/location';
import type {
	UserLocationAccessRequests,
	UserProgramAssociations,
} from '../models/location-access';

const UnexpectedErrorMessage = 'An unexpected error occurred while calling the location access API';

export async function getProgramAssociations(): Promise<UserProgramAssociations> {
	try {
		const response = await axios.get<UserProgramAssociations>(
			'/api/location-access-request/programs',
		);
		return response.data;
	} catch {
		throw new Error(UnexpectedErrorMessage);
	}
}

export async function getLocationAccessRequests(): Promise<UserLocationAccessRequests> {
	try {
		const response = await axios.get<UserLocationAccessRequests>(
			'/api/location-access-request/locations',
		);
		return response.data;
	} catch {
		throw new Error(UnexpectedErrorMessage);
	}
}

export async function deleteLocationAccessRequest(
	locationId: LocationId,
	removeAccess: boolean,
): Promise<void> {
	try {
		await axios.post('/api/location-access-request/delete', { locationId, removeAccess });
	} catch {
		throw new Error(UnexpectedErrorMessage);
	}
}

export async function saveAccessRequests(locationIds: LocationId[]): Promise<void> {
	try {
		await axios.post('/api/location-access-request/save', locationIds);
	} catch {
		throw new Error(UnexpectedErrorMessage);
	}
}
