import { queryOptions } from '@tanstack/react-query';

import { getLocationAccessRequests, getProgramAssociations } from '../api/location-access-request';

export const locationAccessProgramsQueryOptions = queryOptions({
	queryKey: ['location-access-request', 'programs'],
	queryFn: () => getProgramAssociations(),
});

export const locationAccessLocationsQueryOptions = queryOptions({
	queryKey: ['location-access-request', 'locations'],
	queryFn: () => getLocationAccessRequests(),
});
