import axios, { AxiosError } from 'axios';

import { ServerError } from '@apple/utils/api';

import { keywordSchema } from '../models/management';
import type { Keyword, NewKeyword } from '../models/management';

/**
 * Gets all product keywords.
 * @see Manzanita.Web.Features.Catalog.Keywords.ProductKeywordsController
 */
export async function getKeywords(): Promise<Keyword[]> {
	const response = await axios.get('/api/management/product-keywords');

	return keywordSchema.array().parse(response.data);
}

/**
 * Gets the product keyword with the given ID.
 * @see Manzanita.Web.Features.Catalog.Keywords.ProductKeywordsController
 */
export async function getKeywordById(keywordId: number): Promise<Keyword | null> {
	try {
		const response = await axios.get(`/api/management/product-keywords/${keywordId}`);
		return keywordSchema.parse(response.data);
	} catch (error) {
		if (
			error instanceof ServerError &&
			error.innerError instanceof AxiosError &&
			error.innerError.status === 404
		) {
			return null;
		}
		throw error;
	}
}

/**
 * Saves the given keyword.
 * @see Manzanita.Web.Features.Catalog.Keywords.ProductKeywordsController
 */
export async function saveKeyword(keyword: NewKeyword): Promise<Keyword> {
	const response = await axios.post('/api/management/product-keywords', keyword);

	return keywordSchema.parse(response.data);
}
