import { Card, Center, rem, ScrollAreaAutosize, Text } from '@mantine/core';

import { AnnouncementIcon } from '@apple/assets/icons';
import { useTranslation } from '@apple/lib/i18next';
import { MenuPopover } from '@apple/ui/shell/components/MenuPopover';
import { useLogger } from '@apple/utils/logging/useLogger';

import { ActiveAnnouncements } from './ActiveAnnouncements';
import { AnnouncementMenuContent } from './AnnouncementMenuContent';
import type { AnnouncementList } from '../models/announcement';

type Props = {
	loading: boolean;
	values: AnnouncementList | null;
};

export function AnnouncementMenu({ loading, values }: Props) {
	const { t } = useTranslation('common');

	useLogger({
		name: 'AnnouncementMenu',
		props: [{ values }],
		log: 'announcement',
	});

	return (
		<>
			{values !== null && <ActiveAnnouncements announcementList={values} />}
			<MenuPopover
				icon={AnnouncementIcon}
				loading={loading}
				tooltipLabel={t('mainMenu.admin.content.announcements')}
				indicatorCount={values?.unreadCount ?? 0}
				indicatorDisabled={(values?.unreadCount ?? 0) <= 0}
				iconTestId='notification-icon'
				indicatorTestId='notification-badge'
			>
				<Card.Section
					m={0}
					p='md'
					style={{
						borderRadius: `${rem(24)} ${rem(24)} 0 0`,
						backgroundColor: 'var(--apple-color-card-header-bg)',
					}}
				>
					<Center>
						<Text fw='bold'>{t('mainMenu.admin.content.announcements')}</Text>
					</Center>
				</Card.Section>

				<Card.Section m='sm' mih={200} h='100%'>
					<ScrollAreaAutosize scrollbars='y' mah={500}>
						<AnnouncementMenuContent />
					</ScrollAreaAutosize>
				</Card.Section>
			</MenuPopover>
		</>
	);
}
