import { z } from 'zod';

/**
 * These values are passed to the snapshot odata api's via the url to apply additional custom server-side filtering.
 * @see Manzanita.Web.Features.Snapshot.SnapshotPerspectiveFilters */
export interface CommonFilters {
	warehouseId?: number | null | undefined;
	countryCode?: string | null | undefined;
	customerGroupId?: number | null | undefined;
	customerId?: number | null | undefined;
	productCategoryId?: number | null | undefined;
	userRoleId?: number | null | undefined;
	userId?: number | null | undefined;
	allAssociationsOnly?: boolean | null | undefined;
}

/**
 * These values are passed to the snapshot odata api's via the url to apply additional custom server-side filtering.
 * @see Manzanita.Web.Features.Snapshot.SnapshotPerspectiveFilters */
export const commonFiltersSchema = z.object({
	warehouseId: z.onumber().nullable(),
	countryCode: z.ostring().nullable(),
	customerGroupId: z.onumber().nullable(),
	customerId: z.onumber().nullable(),
	productCategoryId: z.onumber().nullable(),
	userRoleId: z.onumber().nullable(),
	userId: z.onumber().nullable(),
	allAssociationsOnly: z.oboolean().nullable(),
}) satisfies z.ZodType<CommonFilters> as z.ZodType<CommonFilters>;

/**
 * This request is based on Kendo's DataSourceRequest.
 * @see: Manzanita.Web.ApiControllers.Dto.KendoDataSourceRequest
 **/
export interface FilterRequest {
	filter: FilterDescriptor;
	take?: number;
	skip?: number;
	page?: number;
	pageSize?: number;
}

/**
 * @see: Manzanita.Web.ApiControllers.Dto.KendoFilterDescriptor
 **/
interface FilterDescriptor {
	logic: LogicalOperator;
	filters: FilterParameter[];
}

/**
 * @see: Manzanita.Web.ApiControllers.Dto.KendoLogicalOperator
 */
export type LogicalOperator = 'And' | 'Or';

/**
 * @see: Manzanita.Web.ApiControllers.Dto.KendoFilterParameter
 */
interface FilterParameter {
	value: string;
	operator: FilterOperator;
	field: string;
	ignoreCase: boolean;
}

/**
 * @see: Manzanita.Web.ApiControllers.Dto.KendoFilterOperator
 */
export type FilterOperator =
	| 'Contains'
	| 'IsContainedIn'
	| 'DoesNotContain'
	| 'Lt'
	| 'Lte'
	| 'Eq'
	| 'Neq'
	| 'Gte'
	| 'Gt'
	| 'StartsWith'
	| 'EndsWith';
