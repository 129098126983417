import axios from 'axios';

import type { PaginatedListDto } from '@apple/utils/pagination';

import { categorySchema } from '../models/category';
import type { Category } from '../models/category';

/** @see Manzanita.Web.Features.Catalog.Categories.ProductCategoriesController */
export async function getById(id: number): Promise<Category> {
	const response = await axios.get(`/api/management/product-categories/${id}`);

	return categorySchema.parse(response.data);
}

/**
 * Get a list of all product categories.
 * @see Manzanita.Web.Features.Catalog.Categories.ProductCategoriesODataController
 */
export async function getProductCategoryListings(): Promise<Category[]> {
	// TODO: Zod schema for pagination - also this is technically an odata api for categories
	const response = await axios.get<PaginatedListDto<Category>>(
		'/odata/management/product-categories',
	);

	return response.data.items;
}

/** @see Manzanita.Web.Features.Catalog.Categories.ProductCategoriesController */
export async function getProductCategories(): Promise<Category[]> {
	const response = await axios.get('/api/management/product-categories');

	return categorySchema.array().parse(response.data);
}
