import { redirect } from '@tanstack/react-router';
import { getLogger } from 'loglevel';
import type { ParsedLocation } from '@tanstack/react-router';

import type { ProfileDto } from '@apple/features/auth/models/user';

import { isAuthorized as hasAuthorization } from '../utils/requirements';
import type { AuthContextBase } from '../contexts/auth-context';
import type { AuthOptions } from '../utils/requirements';

const log = getLogger('auth');

interface RequireAuthRouteContext<TProfile extends ProfileDto> {
	auth: AuthContextBase<TProfile>;
}

export type RequireAuthContext<
	TProfile extends ProfileDto,
	TContext extends RequireAuthRouteContext<TProfile>,
> = {
	context: TContext;
	location: ParsedLocation;
	cause: 'preload' | 'enter' | 'stay';
};

export function requireAuth<
	TProfile extends ProfileDto,
	TContext extends RequireAuthRouteContext<TProfile>,
>(
	{ context, location, cause }: RequireAuthContext<ProfileDto, TContext>,
	options?: Omit<AuthOptions, 'requireAuth'>,
) {
	if (cause === 'stay') {
		return;
	}

	const authOptions: AuthOptions = {
		requireAuth: true,
		...options,
	};
	log.debug(`Attempting to ${cause} protected route: ${location.pathname}`, {
		authOptions,
		context,
	});

	if (hasAuthorization(context.auth.profile, authOptions)) {
		return;
	}

	if (context.auth.authenticated) {
		log.debug(
			'Authenticated, but missing required permissions or roles. Redirecting to:',
			'/not-found',
		);
		throw redirect({
			to: '/not-found',
		});
	}

	// Use the current location to power a redirect after login
	// (Do not use `router.state.resolvedLocation` as it can
	// potentially lag behind the actual current location)
	const returnUrl = options?.returnUrl ?? location.href;

	log.debug('Not authenticated, redirecting to the login page with returnUrl:', returnUrl);

	throw redirect({
		to: '/login',
		search: {
			// No need to add the returnUrl if it is the default. Cleans up the url.
			returnUrl: returnUrl === '/' ? undefined : returnUrl,
		},
	});
}
