import { useMemo } from 'react';
import { Alert, Container, Group, Select, SimpleGrid, TextInput } from '@mantine/core';

import { icons } from '@apple/assets';
import { UpdateProfileSchema } from '@apple/features/auth';
import { useTranslation } from '@apple/lib/i18next';
import { ServerValidatedZodForm } from '@apple/ui/form';
import type { ProfileDto, UpdateProfileRequest } from '@apple/features/auth';
import type {
	CountryOption,
	CurrencyOption,
	LanguageOption,
	OptionKeyValue,
	TimeZoneOption,
} from '@apple/utils/globalization';

export type ProfileProps = {
	profile: ProfileDto;
	timeZones: TimeZoneOption[];
	languages: LanguageOption[];
	currencies: CurrencyOption[];
	countries: CountryOption[];
	onSubmit: (request: UpdateProfileRequest) => Promise<void>;
	showSuccessMessage: boolean;
	setShowSuccessMessage: (state: boolean) => void;
};

export type ProfileFormValues = {
	username: string;
	firstname: string;
	lastname: string;
	email: string;
	timezone: string;
	language: string;
	country: string;
	currency: string;
};

const getOptionKey = (options: OptionKeyValue[], currentValue: string) => {
	return options.some(x => x.Key === currentValue) ? currentValue : (options[0]?.Key ?? '');
};

export function ProfileForm({
	profile,
	countries,
	currencies,
	languages,
	timeZones,
	onSubmit,
	showSuccessMessage,
	setShowSuccessMessage,
}: ProfileProps) {
	const { t } = useTranslation('profile');

	async function handleSubmit(data: ProfileFormValues) {
		const request = {
			userName: data.username,
			firstName: data.firstname,
			lastName: data.lastname,
			emailAddress: data.email,
			language: data.language,
			timeZone: data.timezone,
			numberFormatCountry: data.country,
			currencyIsoCode: data.currency,
		} as UpdateProfileRequest;

		await onSubmit(request);
		setShowSuccessMessage(true);
	}

	const initialValues: ProfileFormValues = useMemo(
		() => ({
			username: profile.userName,
			firstname: profile.firstName,
			lastname: profile.lastName,
			email: profile.emailAddress,
			timezone: getOptionKey(timeZones, profile.timeZone),
			language: getOptionKey(languages, profile.language),
			country: getOptionKey(countries, profile.numberFormatCountry),
			currency: getOptionKey(currencies, profile.currencyIsoCode),
		}),
		[
			countries,
			currencies,
			languages,
			profile.currencyIsoCode,
			profile.emailAddress,
			profile.firstName,
			profile.language,
			profile.lastName,
			profile.numberFormatCountry,
			profile.timeZone,
			profile.userName,
			timeZones,
		],
	);

	const timeZoneOptions = timeZones.map(option => ({
		value: option.Key,
		label: option.Value,
	}));
	const languageOptions = languages.map(option => ({
		value: option.Key,
		label: option.Value,
	}));
	const currencyOptions = currencies.map(option => ({
		value: option.Key,
		label: option.Value,
	}));
	const countryOptions = countries.map(option => ({
		value: option.Key,
		label: option.Value,
	}));

	return (
		<SimpleGrid cols={{ base: 1, md: 2 }}>
			<ServerValidatedZodForm
				name='profile-form'
				validationType='ProfileSettings'
				schema={UpdateProfileSchema}
				initialValues={initialValues}
				fallbackErrorMessage={t('profile.save.fail.message')}
				onValidSubmit={handleSubmit}
				onValuesChange={() => setShowSuccessMessage(false)}
			>
				{({ form, renderSubmitButton, renderFormErrors }) => (
					<>
						<TextInput
							{...form.getInputProps('username')}
							label={t('profile.userName')}
							value={profile.userName}
							readOnly
							disabled
							mt='md'
							data-testid='username'
						/>
						<TextInput
							label={t('profile.userRole')}
							value={profile.roles.length > 0 ? profile.roles[0] : ''}
							readOnly
							disabled
							mt='sm'
							data-testid='role'
						/>
						<TextInput
							{...form.getInputProps('firstname')}
							label={t('profile.firstName')}
							placeholder={t('profile.inputPlaceholder')}
							required
							mt='sm'
							data-testid='firstname'
						/>
						<TextInput
							{...form.getInputProps('lastname')}
							label={t('profile.lastName')}
							placeholder={t('profile.inputPlaceholder')}
							required
							mt='sm'
							data-testid='lastname'
						/>
						<TextInput
							{...form.getInputProps('email')}
							label={t('profile.emailAddress')}
							placeholder={t('profile.inputPlaceholder')}
							required
							mt='sm'
							data-testid='email'
						/>
						<Select
							data={timeZoneOptions}
							label={t('profile.timeZone')}
							value={form.values.timezone}
							onChange={(value: string | null) =>
								value && form.setFieldValue('timezone', value)
							}
							required
							mt='sm'
						/>
						<Select
							data={languageOptions}
							label={t('profile.language')}
							value={form.values.language}
							onChange={(value: string | null) =>
								value && form.setFieldValue('language', value)
							}
							required
							mt='sm'
						/>
						<Select
							data={countryOptions}
							label={t('profile.country')}
							value={form.values.country}
							onChange={(value: string | null) =>
								value && form.setFieldValue('country', value)
							}
							required
							mt='sm'
						/>
						<Select
							data={currencyOptions}
							label={t('profile.currency')}
							value={form.values.currency}
							onChange={(value: string | null) =>
								value && form.setFieldValue('currency', value)
							}
							required
							mt='sm'
						/>
						<Container size='xs' mt='xs'>
							{showSuccessMessage && (
								<Alert
									variant='light'
									color='blue'
									icon={<icons.Success />}
									data-testid='success-message'
								>
									{t('profile.save.success.message')}
								</Alert>
							)}
						</Container>
						<Group justify='flex-end' mt='md'>
							{renderSubmitButton({
								text: t('profile.save.saveButton'),
								fullWidth: false,
								mt: 'lg',
								disableIfInvalid: true,
								disableIfNotDirty: false,
							})}
						</Group>
					</>
				)}
			</ServerValidatedZodForm>
		</SimpleGrid>
	);
}
