import { z } from 'zod';

export interface LocationFeed {
	id: string;
	appleId: string;
	headquartersId: string | null;
	storeName: string;
	retailerName: string;
	storeAddressLine1: string;
	storeAddressLine2: string | null;
	storeAddressLine3: string | null;
	storeCity: string;
	storeStateOrProvince: string | null;
	storePostalCode: string;
	storeCountryCode: string;
	customerGroupIds: number[];
	isDeleted: boolean;
}

export interface LocationFeedListing {
	id: string;
	displayName: string;
	addressLine1: string;
	addressLine2: string | null;
	addressLine3: string | null;
	city: string;
	stateOrProvince: string;
	countryCode: string;
	countryName: string;
	postalCode: string;
	resellerName: string;
	appleId: string;
	headquartersId: string | null;
}

export interface LocationFeedFilters
	extends Partial<
		Omit<
			LocationFeedListing,
			'id' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'postalCode'
		>
	> {}

export const locationFeedSchema = z.object({
	id: z.string(),
	appleId: z.string(),
	headquartersId: z.string().nullable(),
	storeName: z.string(),
	retailerName: z.string(),
	storeAddressLine1: z.string(),
	storeAddressLine2: z.string().nullable(),
	storeAddressLine3: z.string().nullable(),
	storeCity: z.string(),
	storeStateOrProvince: z.string().nullable(),
	storePostalCode: z.string(),
	storeCountryCode: z.string(),
	customerGroupIds: z.array(z.number()),
	isDeleted: z.boolean(),
}) satisfies z.ZodType<LocationFeed>;

const locationFeedListingShape = z.object({
	id: z.string(),
	displayName: z.string(),
	addressLine1: z.string(),
	addressLine2: z.string().nullable(),
	addressLine3: z.string().nullable(),
	city: z.string(),
	stateOrProvince: z.string(),
	countryCode: z.string(),
	countryName: z.string(),
	postalCode: z.string(),
	resellerName: z.string(),
	appleId: z.string(),
	headquartersId: z.string().nullable(),
});

export const locationFeedListingSchema = locationFeedListingShape as z.ZodType<LocationFeedListing>;

export const locationFeedFiltersSchema = locationFeedListingShape
	.omit({
		id: true,
		addressLine1: true,
		addressLine2: true,
		addressLine3: true,
		postalCode: true,
	})
	.partial() satisfies z.ZodType<LocationFeedFilters>;
