import { List, Space, Stack, Text, Title } from '@mantine/core';

import { icons } from '@apple/assets';
import { useTranslation } from '@apple/lib/i18next';
import type { UserProgramAssociations } from '@apple/features/location';

export type ProgramAssociationsProps = {
	associations: UserProgramAssociations;
};

export function ProgramAssociations({ associations }: ProgramAssociationsProps) {
	const { t } = useTranslation('profile');

	const plantAssociations = (
		<List withPadding icon={<icons.Success />}>
			{associations.plantAssociations.map(association => (
				<List.Item key={association}>{association}</List.Item>
			))}
		</List>
	);

	const countryAssociations = (
		<List withPadding icon={<icons.Success />}>
			{associations.countryAssociations.map(association => (
				<List.Item key={association}>{association}</List.Item>
			))}
		</List>
	);

	const programAssociations = (
		<List withPadding icon={<icons.Success />}>
			{associations.programAssociations.map(association => (
				<List.Item key={association}>{association}</List.Item>
			))}
		</List>
	);

	const subProgramAssociations = (
		<List withPadding icon={<icons.Success />}>
			{associations.subProgramAssociations.map(association => (
				<List.Item key={association}>{association}</List.Item>
			))}
		</List>
	);

	return (
		<Stack>
			<Title mt={20} order={3}>
				{t('programsAssociations.title')}
			</Title>
			<Text>{t('programsAssociations.description')}</Text>
			<Space h='md' />

			{associations.allAssociations && (
				<>
					<Title order={4}>{t('programsAssociations.allAccess')}</Title>
					<Text>{t('programsAssociations.allAccessDescription')}</Text>
				</>
			)}

			{associations.plantAssociations.length > 0 && (
				<>
					<Title order={4}>{t('programsAssociations.plantAccess')}</Title>
					<Text>{t('programsAssociations.plantAccessDescription')}</Text>
					{plantAssociations}
				</>
			)}

			{associations.countryAssociations.length > 0 && (
				<>
					<Title order={4}>{t('programsAssociations.countryAccess')}</Title>
					<Text>{t('programsAssociations.countryAccessDescription')}</Text>
					{countryAssociations}
				</>
			)}

			{associations.programAssociations.length > 0 && (
				<>
					<Title order={4}>{t('programsAssociations.programAccess')}</Title>
					<Text>{t('programsAssociations.programAccessDescription')}</Text>
					{programAssociations}
				</>
			)}

			{associations.subProgramAssociations.length > 0 && (
				<>
					<Title order={4}>{t('programsAssociations.subProgramAccess')}</Title>
					<Text>{t('programsAssociations.subProgramAccessDescription')}</Text>
					{subProgramAssociations}
				</>
			)}
		</Stack>
	);
}
