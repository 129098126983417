import { z } from 'zod';

export interface Country {
	code: CountryCode;
	name: string;
}

// For locales like en-US
// export type Locale = `${LanguageCode}-${CountryCode}`;
export type Locale = string;
// For ISO 639-1 language codes like `en`
export type LanguageCode = 'en' | 'ja' | 'zh' | string; // `${LowercaseLetters}${LowercaseLetters}`;
export type CountryCode = z.infer<typeof countryCodeSchema>;

// For ISO 4217 currency codes like `USD`
export type CurrencyCode =
	| 'AUD'
	| 'BRL'
	| 'CAD'
	| 'CNY'
	| 'EUR'
	| 'GBP'
	| 'HKD'
	| 'JPY'
	| 'RUB'
	| 'USD';

export type OptionKeyValue<TKey extends string = string, TValue extends string = string> = {
	Key: TKey;
	Value: TValue;
};

export type TimeZoneOption = OptionKeyValue;
export type CountryOption = OptionKeyValue<CountryCode, string>;
export type CurrencyOption = OptionKeyValue;
export type LanguageOption = OptionKeyValue;
export type DateTime = z.infer<typeof dateTimeSchema>;
export type EmptyOrNullableDateTime = z.infer<typeof emptyOrNullableDateTimeSchema>;

export interface CountryWithCultures {
	code: CountryCode;
	name: string;
	cultures: Locale[];
}

export interface Currency {
	isoCode: string;
	englishName: string;
	localSymbol: string;
}

// Empty string literal in nullableDateTimeSchema is a workaround for emptying a controlled date input
export const dateTimeSchema = z.union([z.date(), z.string().datetime().pipe(z.coerce.date())]);
export const emptyOrNullableDateTimeSchema = z.union([z.literal(''), dateTimeSchema.nullable()]);
// TODO - will coerce be correct?
export const localDateTimeSchema = z.union([
	z.date(),
	z.string().datetime({ local: true }).pipe(z.coerce.date()),
]);
export const emptyOrNullableLocalDateTimeSchema = z.union([
	z.literal(''),
	localDateTimeSchema.nullable(),
]);
export const dateTimeOffsetSchema = z.string().datetime({ offset: true }).pipe(z.coerce.date());

export const currencyCodeSchema = z.string();
export const countryCodeSchema = z.union([
	z.literal('AD'),
	z.literal('AE'),
	z.literal('AF'),
	z.literal('AG'),
	z.literal('AI'),
	z.literal('AL'),
	z.literal('AM'),
	z.literal('AN'),
	z.literal('AO'),
	z.literal('AQ'),
	z.literal('AR'),
	z.literal('AS'),
	z.literal('AT'),
	z.literal('AU'),
	z.literal('AW'),
	z.literal('AX'),
	z.literal('AZ'),
	z.literal('BA'),
	z.literal('BB'),
	z.literal('BD'),
	z.literal('BE'),
	z.literal('BF'),
	z.literal('BG'),
	z.literal('BH'),
	z.literal('BI'),
	z.literal('BJ'),
	z.literal('BL'),
	z.literal('BM'),
	z.literal('BN'),
	z.literal('BO'),
	z.literal('BQ'),
	z.literal('BR'),
	z.literal('BS'),
	z.literal('BT'),
	z.literal('BV'),
	z.literal('BW'),
	z.literal('BY'),
	z.literal('BZ'),
	z.literal('CA'),
	z.literal('CC'),
	z.literal('CD'),
	z.literal('CF'),
	z.literal('CG'),
	z.literal('CH'),
	z.literal('CI'),
	z.literal('CK'),
	z.literal('CL'),
	z.literal('CM'),
	z.literal('CN'),
	z.literal('CO'),
	z.literal('CR'),
	z.literal('CU'),
	z.literal('CV'),
	z.literal('CW'),
	z.literal('CX'),
	z.literal('CY'),
	z.literal('CZ'),
	z.literal('DE'),
	z.literal('DJ'),
	z.literal('DK'),
	z.literal('DM'),
	z.literal('DO'),
	z.literal('DZ'),
	z.literal('EC'),
	z.literal('EE'),
	z.literal('EG'),
	z.literal('EH'),
	z.literal('ER'),
	z.literal('ES'),
	z.literal('ET'),
	z.literal('FI'),
	z.literal('FJ'),
	z.literal('FK'),
	z.literal('FM'),
	z.literal('FO'),
	z.literal('FR'),
	z.literal('GA'),
	z.literal('GB'),
	z.literal('GD'),
	z.literal('GE'),
	z.literal('GF'),
	z.literal('GG'),
	z.literal('GH'),
	z.literal('GI'),
	z.literal('GL'),
	z.literal('GM'),
	z.literal('GN'),
	z.literal('GP'),
	z.literal('GQ'),
	z.literal('GR'),
	z.literal('GS'),
	z.literal('GT'),
	z.literal('GU'),
	z.literal('GW'),
	z.literal('GY'),
	z.literal('HK'),
	z.literal('HM'),
	z.literal('HN'),
	z.literal('HR'),
	z.literal('HT'),
	z.literal('HU'),
	z.literal('IC'),
	z.literal('ID'),
	z.literal('IE'),
	z.literal('IL'),
	z.literal('IM'),
	z.literal('IN'),
	z.literal('IO'),
	z.literal('IQ'),
	z.literal('IR'),
	z.literal('IS'),
	z.literal('IT'),
	z.literal('JE'),
	z.literal('JM'),
	z.literal('JO'),
	z.literal('JP'),
	z.literal('KE'),
	z.literal('KG'),
	z.literal('KH'),
	z.literal('KI'),
	z.literal('KM'),
	z.literal('KN'),
	z.literal('KP'),
	z.literal('KR'),
	z.literal('KW'),
	z.literal('KY'),
	z.literal('KZ'),
	z.literal('LA'),
	z.literal('LB'),
	z.literal('LC'),
	z.literal('LI'),
	z.literal('LK'),
	z.literal('LR'),
	z.literal('LS'),
	z.literal('LT'),
	z.literal('LU'),
	z.literal('LV'),
	z.literal('LY'),
	z.literal('MA'),
	z.literal('MC'),
	z.literal('MD'),
	z.literal('ME'),
	z.literal('MF'),
	z.literal('MG'),
	z.literal('MH'),
	z.literal('MK'),
	z.literal('ML'),
	z.literal('MM'),
	z.literal('MN'),
	z.literal('MO'),
	z.literal('MP'),
	z.literal('MQ'),
	z.literal('MR'),
	z.literal('MS'),
	z.literal('MT'),
	z.literal('MU'),
	z.literal('MV'),
	z.literal('MW'),
	z.literal('MX'),
	z.literal('MY'),
	z.literal('MZ'),
	z.literal('NA'),
	z.literal('NC'),
	z.literal('NE'),
	z.literal('NF'),
	z.literal('NG'),
	z.literal('NI'),
	z.literal('NL'),
	z.literal('NO'),
	z.literal('NP'),
	z.literal('NR'),
	z.literal('NU'),
	z.literal('NZ'),
	z.literal('OM'),
	z.literal('PA'),
	z.literal('PE'),
	z.literal('PF'),
	z.literal('PG'),
	z.literal('PH'),
	z.literal('PK'),
	z.literal('PL'),
	z.literal('PM'),
	z.literal('PN'),
	z.literal('PR'),
	z.literal('PS'),
	z.literal('PT'),
	z.literal('PW'),
	z.literal('PY'),
	z.literal('QA'),
	z.literal('RE'),
	z.literal('RO'),
	z.literal('RS'),
	z.literal('RU'),
	z.literal('RW'),
	z.literal('SA'),
	z.literal('SB'),
	z.literal('SC'),
	z.literal('SD'),
	z.literal('SE'),
	z.literal('SG'),
	z.literal('SH'),
	z.literal('SI'),
	z.literal('SJ'),
	z.literal('SK'),
	z.literal('SL'),
	z.literal('SM'),
	z.literal('SN'),
	z.literal('SO'),
	z.literal('SR'),
	z.literal('ST'),
	z.literal('SV'),
	z.literal('SX'),
	z.literal('SY'),
	z.literal('SZ'),
	z.literal('TC'),
	z.literal('TD'),
	z.literal('TF'),
	z.literal('TG'),
	z.literal('TH'),
	z.literal('TJ'),
	z.literal('TK'),
	z.literal('TL'),
	z.literal('TM'),
	z.literal('TN'),
	z.literal('TO'),
	z.literal('TP'),
	z.literal('TR'),
	z.literal('TT'),
	z.literal('TV'),
	z.literal('TW'),
	z.literal('TZ'),
	z.literal('UA'),
	z.literal('UG'),
	z.literal('UM'),
	z.literal('US'),
	z.literal('UY'),
	z.literal('UZ'),
	z.literal('VA'),
	z.literal('VC'),
	z.literal('VE'),
	z.literal('VG'),
	z.literal('VI'),
	z.literal('VN'),
	z.literal('VU'),
	z.literal('WF'),
	z.literal('WS'),
	z.literal('XI'),
	z.literal('XK'),
	z.literal('XX'),
	z.literal('YE'),
	z.literal('YT'),
	z.literal('ZA'),
	z.literal('ZM'),
	z.literal('ZW'),
]);
export const cultureCodeSchema = z.string();

export const countryWithCulturesSchema = z.object({
	code: countryCodeSchema,
	name: z.string(),
	cultures: z.array(cultureCodeSchema),
}) satisfies z.ZodType<CountryWithCultures>;

export const currencySchema = z.object({
	isoCode: currencyCodeSchema,
	englishName: z.string(),
	localSymbol: z.string(),
}) satisfies z.ZodType<Currency>;

export const DEFAULT_CULTURE = 'en-US';
