import axios from 'axios';
import log from 'loglevel';

import { ServerError } from '@apple/utils/api';
import type { Country } from '@apple/utils/globalization';

import { isLoginFailedResponse, isLoginSuccessResponse, loginRequestSchema } from '../models/login';
import { PasswordValidationErrorType } from '../models/user';
import type { LoginRequest, LoginSuccessResponse } from '../models/login';
import type {
	ChangePasswordRequest,
	ResetPasswordResponse,
	SetPasswordRequest,
} from '../models/user';

export async function login(request: LoginRequest): Promise<LoginSuccessResponse> {
	// Validate the request object
	request = loginRequestSchema.parse(request);

	// Cookie Auth
	// Returns 200 with { result: true, redirectUrl?: string } if successful
	// Returns 200 with { result: false, message?: string } if failed
	const response = await axios.post('/api/authentication/login', request, {
		withCredentials: true,
		// Don't follow server redirects because they point to the legacy pages
		maxRedirects: 0,
	});

	if (isLoginSuccessResponse(response.data)) {
		return response.data;
	}

	if (isLoginFailedResponse(response.data)) {
		throw new ServerError(response.data.message, {
			cause: response.data.errorCode,
		});
	}

	log.error('Unexpected response from the server', response);
	throw new ServerError('Failed to login.');
}

export async function logout() {
	try {
		await axios.post('/api/authentication/logout', undefined, {
			// Don't follow server redirects because they point to the legacy pages
			maxRedirects: 0,
			// Don't throw an error if the server returns a redirect
			validateStatus: status => status < 400,
		});
	} catch (err) {
		// TODO: Handle the response object from the server
		// https://github.com/aspnet/AspNetKatana/blob/43996b47015ca0c0ad12cdb6c87a017534eec620/src/Microsoft.Owin.Security.Cookies/Provider/DefaultBehavior.cs#L27
		log.warn('Ignoring server error which occurred while attempting to logout.', err);
	}
}

export async function getXsrfToken(): Promise<boolean> {
	try {
		await axios.get('/api/authentication/xsrf-token');
		return true;
	} catch (error) {
		log.error('Unable to get token.', error);
		throw error;
	}
}

/** See: AuthenticationController.RetrievePassword */
export async function resetPassword(username: string): Promise<void> {
	await axios.post<string>('/api/authentication/reset-password', {
		userName: username,
		isSpa: true,
	});
}

export async function changePassword(request: ChangePasswordRequest): Promise<void> {
	await axios.post('/api/profile/password/change', request);
}

/** Gets a list of countres that the current user has access to */
export async function getCountries() {
	return (await axios.get<Country[]>('/api/profile/countries')).data;
}

/** See: ResetPasswordApiController  **/
export async function validateToken(token: string): Promise<ResetPasswordResponse> {
	return (await axios.post<ResetPasswordResponse>(`/api/authentication/verify-token`, { token }))
		.data;
}

export async function setPassword(request: SetPasswordRequest) {
	try {
		await axios.post<ResetPasswordResponse>('/api/authentication/set-password', request);
	} catch (error) {
		if (error instanceof ServerError) {
			switch (error.message) {
				case 'PasswordValidation_CannotMatchUserName':
					throw new ServerError(PasswordValidationErrorType.cannotMatchUsername);
				case 'PasswordValidation_InvalidLength':
					throw new ServerError(PasswordValidationErrorType.invalidLength);
				case 'PasswordValidation_InvalidCharacter':
					throw new ServerError(PasswordValidationErrorType.invalidCharacter);
				case 'PasswordValidation_MissingRequiredCharacters':
					throw new ServerError(PasswordValidationErrorType.missingRequiredCharacters);
			}
		}
		throw error;
	}
}
