import { z } from 'zod';
import type { ZodType } from 'zod';

import { countryCodeSchema, emptyOrNullableDateTimeSchema } from '@apple/utils/globalization';
import type { CountryCode } from '@apple/utils/globalization';

export interface Subprogram {
	name: string;
	programId: number;
	programName: string;
	customerId: number;
	customerCode: string;
	countryCode: CountryCode;
	countryName: string;
	plantName: string;
	plantAbbreviation: string;
	activeFrom: Date | '' | null;
	activeUntil: Date | '' | null;
	status: string;
	expeditedShippingFee?: number | null;
}

export interface SubprogramFilters {
	programName?: string;
	customerCode?: string;
	countryName?: string;
	status?: string;
}

export const subprogramSchema = z.object({
	name: z.string(),
	programId: z.number(),
	programName: z.string(),
	customerId: z.number(),
	customerCode: z.string(),
	countryCode: countryCodeSchema,
	countryName: z.string(),
	plantName: z.string(),
	plantAbbreviation: z.string(),
	activeFrom: emptyOrNullableDateTimeSchema,
	activeUntil: emptyOrNullableDateTimeSchema,
	status: z.string(),
	expeditedShippingFee: z.number().nullable(),
}) as ZodType<Subprogram>;

export interface SubProgramKey {
	customerId: number;
	countryCode: CountryCode;
}

export interface SubprogramRequest {
	name: string;
	programId: number;
	customerId: number;
	countryCode: CountryCode;
	activeFrom: Date | '' | null;
	activeUntil: Date | '' | null;
	expeditedShippingFee?: number | null;
}

export const subprogramRequestSchema = z.object({
	name: z.string(),
	programId: z.number(),
	customerId: z.number(),
	countryCode: countryCodeSchema,
	activeFrom: emptyOrNullableDateTimeSchema,
	activeUntil: emptyOrNullableDateTimeSchema,
}) as ZodType<SubprogramRequest>;

export interface SubProgramCustomer {
	customerId: number;
	customerCode: string;
}

export const subprogramCustomerSchema = z.object({
	customerId: z.number(),
	customerCode: z.string(),
}) satisfies ZodType<SubProgramCustomer>;
