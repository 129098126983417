import axios from 'axios';

import type { AddressBookId, AddressItem } from '../models';

/** @see AppleBrandedPrograms.Web.Features.Addresses.AddressBookManagementController */
export async function getAddressBookItemById(id: AddressBookId): Promise<AddressItem> {
	const response = await axios.get<AddressItem>(`/api/management/address-book/${id}`);

	return response.data;
}
