import { queryOptions } from '@tanstack/react-query';

import { getKeywords } from '../api/keywords';
import {
	getEolReplacementRecords,
	getInventory,
	getPrices,
	getUnassignedSkus,
} from '../api/management';
import { getProductCategories } from '../api/product-categories';
import type { ProductKey } from '../models/product';

export const productQueryKeys = {
	inventory: (itemId: ProductKey) => ['product', itemId, 'inventory'],
	prices: (itemId: ProductKey) => ['product', itemId, 'prices'],
	eolReplacements: (itemId: ProductKey) => ['product', itemId, 'eol-replacements'],
	keywords: ['products', 'keywords'],
	categories: ['products', 'categories'],
	unassignedSkus: ['products', 'unassigned-skus'],
} as const;

export const productQueryOptions = {
	inventory: (itemId: ProductKey, enabled: boolean) =>
		queryOptions({
			queryKey: productQueryKeys.inventory(itemId),
			queryFn: () => getInventory(itemId),
			staleTime: 15 * 60 * 1000,
			enabled,
		}),
	prices: (itemId: ProductKey, enabled: boolean) =>
		queryOptions({
			queryKey: productQueryKeys.prices(itemId),
			queryFn: () => getPrices(itemId),
			staleTime: 15 * 60 * 1000,
			enabled,
		}),
	eolReplacements: (itemId: ProductKey, enabled: boolean) =>
		queryOptions({
			queryKey: productQueryKeys.eolReplacements(itemId),
			queryFn: () => getEolReplacementRecords(itemId),
			staleTime: 15 * 60 * 1000,
			enabled,
		}),
	keywords: queryOptions({
		queryKey: productQueryKeys.keywords,
		queryFn: getKeywords,
		staleTime: 5 * 60 * 1000, // 5 minutes
	}),
	categories: queryOptions({
		queryKey: productQueryKeys.categories,
		queryFn: getProductCategories,
		staleTime: Infinity,
	}),
	unassignedSkus: (enabled: boolean = true) =>
		queryOptions({
			queryKey: productQueryKeys.unassignedSkus,
			queryFn: () => getUnassignedSkus(),
			staleTime: 15 * 60 * 1000,
			enabled,
		}),
};
