import { useCallback, useMemo, useState } from 'react';
import { functionalUpdate } from '@tanstack/react-router';
import { useTableSearchParams } from 'tanstack-table-search-params';
import type { NavigateOptions } from '@tanstack/react-router';
import type {
	ColumnFilter,
	ColumnFiltersState,
	PaginationState,
	RowData,
	SortingState,
	Updater,
} from '@tanstack/table-core';

import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '@apple/utils/pagination/models';

import { getColumnFiltersFromFilterData, getFilterDataFromColumnFilters } from '../utils/filters';
import type { DataTableState, FieldMap } from '../types';

interface Props<TRow extends RowData, TFilters> {
	search: Record<string, string | undefined>;
	navigate: (params: NavigateOptions) => Promise<void>;
	defaultFilters?: TFilters;
	searchFilters?: ColumnFilter[];
	defaultSorting?: SortingState;
	defaultPageSize?: number;
	fieldMap: FieldMap<TRow, TFilters>[];
}

export function useTableState<TRow, TFilters>({
	search,
	navigate,
	defaultFilters,
	searchFilters = [],
	defaultPageSize = DEFAULT_PAGE_SIZE,
	defaultSorting = [],
	fieldMap,
}: Props<TRow, TFilters>) {
	const defaultColumnFilters = useMemo(() => {
		return !defaultFilters
			? []
			: getColumnFiltersFromFilterData<TRow, TFilters>(defaultFilters, fieldMap);
	}, [defaultFilters, fieldMap]);

	const initialState = useMemo(
		() => getDefaults(defaultColumnFilters, defaultSorting, defaultPageSize),
		[defaultColumnFilters, defaultSorting, defaultPageSize],
	);

	const defaultState: DataTableState = {
		...initialState,
		columnFilters: [...initialState.columnFilters, ...searchFilters],
	};

	const stateAndOnChanges = useTableSearchParams(
		{
			replace: url => {
				const searchParams = new URLSearchParams(url.split('?')[1] ?? '');
				void navigate({
					search: Object.fromEntries(
						searchParams.entries().filter(x => !searchFilters.some(f => f.id === x[0])),
					),
					replace: true,
				});
			},
			query: search,
			pathname: location.pathname,
		},
		{
			defaultValues: defaultState,
			debounceMilliseconds: {
				pagination: 1000,
			},
		},
	);

	const [state, setState] = useState<DataTableState>(stateAndOnChanges.state);

	const currentFilterData = useMemo(
		() => getFilterDataFromColumnFilters<TRow, TFilters>(state.columnFilters, fieldMap),
		[state.columnFilters, fieldMap],
	);

	const resetPagination = useCallback(() => {
		stateAndOnChanges.onPaginationChange({
			...stateAndOnChanges.state.pagination,
			pageIndex: DEFAULT_PAGE_INDEX,
		});
	}, [stateAndOnChanges]);

	const onPaginationChange = useCallback(
		(valueOrUpdater: Updater<PaginationState>) => {
			setState(prev => ({
				...prev,
				pagination: functionalUpdate(valueOrUpdater, prev.pagination),
			}));
			stateAndOnChanges.onPaginationChange(valueOrUpdater);
		},
		[stateAndOnChanges],
	);

	const onColumnFiltersChange = useCallback(
		(valueOrUpdater: Updater<ColumnFiltersState>) => {
			setState(prev => ({
				...prev,
				columnFilters: functionalUpdate(valueOrUpdater, prev.columnFilters),
				pagination: {
					...prev.pagination,
					pageIndex: DEFAULT_PAGE_INDEX, // Reset to first page
				},
			}));
			stateAndOnChanges.onColumnFiltersChange(valueOrUpdater);
			resetPagination();
		},
		[stateAndOnChanges, resetPagination],
	);

	const onSortingChange = useCallback(
		(valueOrUpdater: Updater<SortingState>) => {
			setState(prev => ({
				...prev,
				sorting: functionalUpdate(valueOrUpdater, prev.sorting),
			}));
			stateAndOnChanges.onSortingChange(valueOrUpdater);
		},
		[stateAndOnChanges],
	);

	return {
		initialState,
		state,
		currentFilterData,
		onPaginationChange,
		onColumnFiltersChange,
		onSortingChange,
	};
}

function getDefaults(
	defaultColumnFilters: ColumnFilter[],
	defaultSorting: SortingState,
	pageSize: number,
): DataTableState {
	return {
		pagination: {
			pageIndex: DEFAULT_PAGE_INDEX,
			pageSize: pageSize,
		},
		globalFilter: '',
		columnFilters: defaultColumnFilters,
		sorting: defaultSorting,
	};
}
