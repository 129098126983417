import { keepPreviousData, queryOptions } from '@tanstack/react-query';
import type { PaginationState } from '@tanstack/table-core';

import { getAddressBookItemById } from '@apple/features/address-book/management/api';
import type { AddressBookId } from '@apple/features/address-book/models';

import type { AddressBookItemFilters } from './models';

export const addressBookQueryKeys = {
	all: ['addressbook'],
	addressbook: (addressBookId: AddressBookId) => ['addressbook', addressBookId],
	odata: (filters: AddressBookItemFilters, pagination: PaginationState) => [
		'addressbook',
		'odata',
		filters,
		pagination,
	],
};

export const addressBookQueries = {
	getAddressBookItem: (addressBookId: AddressBookId) =>
		queryOptions({
			queryKey: addressBookQueryKeys.addressbook(addressBookId),
			queryFn: () => getAddressBookItemById(addressBookId),
			placeholderData: keepPreviousData,
			throwOnError: true,
		}),
};
