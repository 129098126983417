import axios from 'axios';

import { plantSchema } from '@apple/features/plants/models/models';
import { userAutocompleteModelSchema } from '@apple/features/user/models/listing';
import type { Plant } from '@apple/features/plants/models/models';
import type { UserAutocompleteModel } from '@apple/features/user/models/listing';

export async function getPlants(): Promise<Plant[]> {
	const data = (await axios.get<unknown>('/api/plants')).data;
	return plantSchema.array().parse(data);
}

export async function autocomplete(searchText: string): Promise<UserAutocompleteModel[]> {
	const response = await axios.get<unknown>('/api/user/autocomplete', {
		params: { searchText },
	});

	return userAutocompleteModelSchema.array().parse(response.data);
}
