import { z } from 'zod';

import type { CartId } from '@apple/features/cart/models/cart-id';
import type { DateTime } from '@apple/utils/primitive';

/** Maps to the `ReviewOrderDetails` validation type in Channel's database */
export type OrderSubmissionRequest = z.infer<typeof orderSubmissionRequestSchema>;

/** Maps to the `ReviewOrderDetails` validation type in Channel's database */
export const orderSubmissionRequestSchema = z.object({
	shopPurchaseOrderNumber: z.string().max(35).optional(),
	shopOrderComments: z.string().max(200).optional(),
	shopOrderName: z.string().max(35).optional(),
	shopProjectType: z.string().optional(),
	shopOrderType: z.string().optional(),
	shopOrderDate: z.string().optional(),
	ghostShipment: z.boolean().optional(),
});

/** Manzanita.Web.ApiControllers.Dto.CompleteBulkOrderRequest */
export type CompleteBulkOrderRequest = Readonly<{
	poNumber: string;
	comments: string;
	isGhostShipment: boolean;
	bypassBudget: boolean;
	orderDate: string;
	acceptedApprovalRequired: boolean;
	orderReason: string;
	orderName: string;
}>;

/** Maps to Manzanita.Order.CartAttributeKey's */
export type CompleteRequestOptions = {
	orderName?: string;
	orderComments?: string;
	orderNumber?: string;
	holdUntilDate?: DateTime;
	userAcknowledgedApprovalRequired?: boolean;
	isGhostShipment?: boolean;
	bypassBudget?: boolean;
};

export enum CartErrorType {
	quantityUpdateConflict = 'quantityUpdateConflict', // Add/update item quantity conflict
	noActiveProgramsAndOrProducts = 'noActiveProgramsAndOrProducts', // Products are no longer available for selected locations and programs
	commentRequired = 'commentRequired',
	quantitiesRequired = 'quantitiesRequired',
	shippingAddressMismatch = 'shippingAddressMismatch',
	inactiveProgram = 'inactiveProgram',
}

/** Manzanita.Web.ApiControllers.Dto.CompleteBulkOrderResponse */
export type CompleteResponse = {
	bulkOrderId?: CartId;
	salesOrderNumber?: string;
	estimatedShipDate?: moment.Moment;
};

/** Manzanita.Web.ApiControllers.Dto.CompleteBulkOrderResponse */
export type CompleteBulkOrderResponse = Readonly<{
	completedBulkOrderId: CartId;
	salesOrderNumber: string;
	// NOTE: The word "date" in "Shipdate" must be lowercased to match the API response...
	estimatedShipdate?: DateTime;
	stepToComplete: string;
}>;
