import { z } from 'zod';

import { dateTimeSchema } from '@apple/utils/globalization';

/** @see Manzanita.Web.Features.Profiles.UserListingModel */
export interface UserFilters
	extends Partial<
		Omit<
			UserListing,
			| 'orderValueLimit'
			| 'lastSignInDate'
			| 'lastOrderDate'
			| 'createDate'
			| 'isActive'
			| 'isLocked'
		>
	> {
	orderValueLimit?:
		| [number | null | undefined | string, number | null | undefined | string]
		| null;
	lastSignInDate?: [string, string] | null;
	createDate?: [string, string] | null;
	isActive?: 'active' | 'inactive';
}

/** @see Manzanita.Web.Features.Profiles.UserAutoCompleteController.UserModel */
export interface UserAutocompleteModel {
	username: string;
	firstName: string;
	lastName: string;
	email: string;
}

/** @see Manzanita.Web.Features.Profiles.UserAutoCompleteController.UserModel */
export const userAutocompleteModelSchema = z.object({
	username: z.string(),
	firstName: z.string(),
	lastName: z.string(),
	email: z.string(),
}) satisfies z.ZodType<UserAutocompleteModel>;

/**
 * @see AppleRetail.Web.Features.Profiles.UserManagementODataController.UserData
 * @see AppleBrandedPrograms.Web.Features.Profiles.UserManagementODataController.UserData
 */
export interface UserListing extends UserAutocompleteModel {
	internalUserId: string;
	userStatus: string;
	userRoles: string;
	userRolesList: string[];
	isActive: boolean;
	createDate: Date;
	activeDate: Date | string | null;
	inactiveDate: Date | string | null;
	lastSignInDate: Date | null;
	customerCount: number;
	numberFormatCountry: string | null;
	culture: string;
	orderValueLimit: number;
}

/** @see Manzanita.Web.Features.Profiles.UserListingModel */
export const baseUserListingSchema = z.object({
	username: z.string(),
	internalUserId: z.string(),
	firstName: z.string(),
	lastName: z.string(),
	email: z.string(),
	userStatus: z.string(),
	userRoles: z.string(),
	isActive: z.boolean(),
	createDate: dateTimeSchema,
	activeDate: dateTimeSchema.nullable(),
	inactiveDate: dateTimeSchema.nullable(),
	lastSignInDate: dateTimeSchema.nullable(),
	customerCount: z.number(),
	numberFormatCountry: z.string().nullable(),
	culture: z.string(),
	userRolesList: z.array(z.string()),
	orderValueLimit: z.coerce.number(),
});

/** @see Manzanita.Web.Features.Profiles.UserListingModel */
export const userListingSchema = baseUserListingSchema as z.ZodSchema<UserListing>;

/** @see Manzanita.Web.Features.Profiles.UserListingModel */
export const baseUserFiltersSchema = baseUserListingSchema.partial().extend({
	orderValueLimit: z
		.tuple([
			z.number().or(z.string()).optional().nullable(),
			z.number().or(z.string()).optional().nullable(),
		])
		.optional()
		.nullable(),
	lastSignInDate: z.tuple([z.string(), z.string()]).optional().nullable(),
	createDate: z.tuple([z.string(), z.string()]).optional().nullable(),
	isActive: z.enum(['active', 'inactive']).optional(),
}) satisfies z.ZodType<UserFilters>;

export const userFiltersSchema = baseUserFiltersSchema as z.ZodType<UserFilters>;
