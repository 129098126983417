import { z } from 'zod';

import { currencySchema } from '@apple/utils/globalization/models';
import type { Currency } from '@apple/utils/globalization/models';

/** Manzanita.Web.Areas.Admin.ApiControllers.CurrencyConversionModel */
export interface CurrencyConversion {
	fromCurrency: Currency;
	toCurrency: Currency;
	conversionFactor: number;
}

/** Manzanita.Web.Areas.Admin.ApiControllers.CurrencyConversionManagementApiController.CurrencyConversionListingItemModel */
export interface CurrencyConversionListing {
	fromCurrencyIsoCode: string;
	toCurrencyIsoCode: string;
	conversionFactor: number;
}

/** Manzanita.Web.Areas.Admin.ApiControllers.CurrencyConversionDeleteItem */
export interface CurrencyConversionDeleteItem {
	fromCurrencyIsoCode: string;
	toCurrencyIsoCode: string;
}

/** Manzanita.Web.Areas.Admin.ApiControllers.CurrencyConversionManagementApiController.CurrencyConversionModel */
export interface CurrencyConversionRequest {
	fromCurrency: string;
	toCurrency: string;
	conversionFactor: number;
}

export interface CurrencyConversionFilters
	extends Partial<Omit<CurrencyConversionListing, 'conversionFactor'>> {}

export const currencyConversionSchema = z.object({
	fromCurrency: currencySchema,
	toCurrency: currencySchema,
	conversionFactor: z.number(),
}) satisfies z.ZodType<CurrencyConversion>;

export const currencyConversionListingSchema = z.object({
	fromCurrencyIsoCode: z.string(),
	toCurrencyIsoCode: z.string(),
	conversionFactor: z.number(),
}) satisfies z.ZodType<CurrencyConversionListing>;

export const currencyConversionFiltersSchema = currencyConversionListingSchema
	.omit({ conversionFactor: true })
	.partial() satisfies z.ZodType<CurrencyConversionFilters>;

export const currencyConversionRequestSchema = z.object({
	fromCurrency: z.string(),
	toCurrency: z.string(),
	conversionFactor: z.number(),
}) satisfies z.ZodType<CurrencyConversionRequest>;
