import type { ZodObject, ZodRawShape, ZodTypeAny } from 'zod';

import { useValidationSchema } from '@apple/utils/validation/hooks/useValidationSchema';
import type { CountryCode } from '@apple/utils/globalization';
import type { AllKeysAreStrings } from '@apple/utils/type';
import type { ValidationType } from '@apple/utils/validation';

import { ValidatedZodForm } from './ValidatedZodForm';
import type { ValidatedFormProps } from './ValidatedForm';

type Props<T extends AllKeysAreStrings<T>, TSchema extends ZodObject<ZodRawShape>> = Omit<
	ValidatedFormProps<T>,
	'validate'
> & {
	validationType: ValidationType;
	countryCode?: CountryCode;
	schema: TSchema;
	schemaExtension?: (schema: TSchema) => ZodTypeAny;
};

export function ServerValidatedZodForm<
	T extends AllKeysAreStrings<T>,
	TSchema extends ZodObject<ZodRawShape>,
>({ validationType, countryCode, schema, schemaExtension, ...rest }: Props<T, TSchema>) {
	const { schema: schemaWithServerRules } = useValidationSchema({
		validationType,
		countryCode,
		existingSchema: schema,
	});

	return (
		<ValidatedZodForm<T, TSchema>
			{...rest}
			schema={schemaWithServerRules}
			schemaExtension={schemaExtension}
		/>
	);
}
