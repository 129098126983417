import { useMemo, useState } from 'react';
import { Alert, Container, Group, PasswordInput, SimpleGrid } from '@mantine/core';

import { icons } from '@apple/assets';
import { createChangePasswordSchema } from '@apple/features/auth';
import { useTranslation } from '@apple/lib/i18next';
import { ServerValidatedZodForm } from '@apple/ui/form';
import type { ChangePasswordRequest } from '@apple/features/auth';

export type ChangePasswordProps = {
	onSubmit: (request: ChangePasswordRequest) => Promise<void>;
};

export type ChangePasswordFormValues = {
	password: string;
	newPassword: string;
	confirmPassword: string;
};

export function ChangePassword({ onSubmit }: ChangePasswordProps) {
	const { t } = useTranslation('profile');
	const [status, setStatus] = useState<'idle' | 'success' | 'fail'>('idle');

	async function handleSubmit(data: ChangePasswordFormValues) {
		const request = {
			currentPassword: data.password,
			newPassword: data.newPassword,
			confirmNewPassword: data.confirmPassword,
		} satisfies ChangePasswordRequest;

		try {
			setStatus('idle');
			await onSubmit(request);
			setStatus('success');
		} catch {
			setStatus('fail');
		}
	}

	const initialValues: ChangePasswordFormValues = useMemo(
		() => ({
			password: '',
			newPassword: '',
			confirmPassword: '',
		}),
		[],
	);

	return (
		<SimpleGrid cols={{ base: 1, md: 2 }}>
			<ServerValidatedZodForm
				name='change-password-form'
				validationType='ChangePassword'
				schema={createChangePasswordSchema(t)}
				schemaExtension={schema => {
					return schema.refine(data => data.newPassword === data.confirmPassword, {
						message: t('profile:changePassword.passwordsNotEqual'),
						path: ['confirmPassword'],
					});
				}}
				initialValues={initialValues}
				fallbackErrorMessage={t('changePassword.save.fail.message')}
				onValidSubmit={handleSubmit}
			>
				{({ form, renderSubmitButton }) => (
					<>
						<PasswordInput
							{...form.getInputProps('password')}
							label={t('changePassword.password')}
							placeholder={t('changePassword.inputPlaceholder')}
							required
							mt='sm'
							data-testid='password'
						/>
						<PasswordInput
							{...form.getInputProps('newPassword')}
							label={t('changePassword.newPassword')}
							placeholder={t('changePassword.inputPlaceholder')}
							required
							mt='sm'
							data-testid='newPassword'
						/>
						<PasswordInput
							{...form.getInputProps('confirmPassword')}
							label={t('changePassword.confirmNewPassword')}
							placeholder={t('changePassword.inputPlaceholder')}
							required
							mt='sm'
							data-testid='confirmPassword'
						/>
						<Container size='xs' mt='xs'>
							{status === 'success' && (
								<Alert
									variant='light'
									color='blue'
									icon={<icons.Success />}
									data-testid='success-message'
								>
									{t('changePassword.save.success.message')}
								</Alert>
							)}
							{status === 'fail' && (
								<Alert
									variant='light'
									color='red'
									icon={<icons.Error />}
									data-testid='fail-message'
								>
									{t('changePassword.save.fail.message')}
								</Alert>
							)}
						</Container>
						<Group justify='flex-end' mt='md'>
							{renderSubmitButton({
								text: t('changePassword.save.saveButton'),
								fullWidth: false,
								mt: 'lg',
								disableIfInvalid: true,
							})}
						</Group>
					</>
				)}
			</ServerValidatedZodForm>
		</SimpleGrid>
	);
}
