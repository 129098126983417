import axios from 'axios';

import { plantListingSchema } from '@apple/features/plants/models/listing';
import type { PlantListing } from '@apple/features/plants/models/listing';

/**
 * @see AppleBrandedPrograms.Web.Areas.Admin.ApiControllers.PlantAdminApiController
 */

export async function getAllPlants(): Promise<PlantListing[]> {
	const response = (await axios.get<{ items: unknown[] }>('odata/management/plants')).data;

	return plantListingSchema.array().parse(response.items);
}

export async function getPlant(warehouseLocationId: string): Promise<PlantListing> {
	const data = (await axios.get<unknown>(`/api/management/plants/${warehouseLocationId}`)).data;
	return plantListingSchema.parse(data);
}

export async function savePlant(plantListing: PlantListing): Promise<void> {
	await axios.put('/api/management/plants', plantListing);
}
