import { queryOptions } from '@tanstack/react-query';

import type { CartId } from '@apple/features/cart/models/cart-id';

import {
	getAvailableShippingOptions,
	getItemsUpdatedCount,
	getLocationSelections,
	getOrCreateBulkOrder,
	getOrderItems,
	getOrderTypes,
	getProductSelections,
	getSavedBulkOrders,
	getSelectedAddresses,
	getSelectedLocationsPlantCount,
	getSelectedPrograms,
} from './api';

export const cartQueryKeys = {
	all: ['cart'],
	types: ['cart', 'types'],
	saved: ['cart', 'saved-orders'],
	cartId: ['cart', 'id'],
	cart: (cartId: CartId) => ['cart', cartId],
	selectedLocations: (cartId: CartId) => ['cart', cartId, 'locations'],
	selectedLocationsPlantCount: (cartId: CartId) => ['cart', cartId, 'locations', 'plant-count'],
	selectedLocationsPrograms: (cartId: CartId) => ['cart', cartId, 'locations', 'programs'],
	selectedProducts: (cartId: CartId) => ['cart', cartId, 'products'],
	selectedQuantities: (cartId: CartId, mode: 'exclude-zero' | 'include-zero' | 'all') =>
		mode === 'all'
			? (['cart', cartId, 'quantities'] as const)
			: (['cart', cartId, 'quantities', mode] as const),
	selectedQuantitiesCount: (cartId: CartId | null) => ['cart', cartId, 'quantities', 'count'],
	availableShippingMethods: ['cart', 'shipping-methods'],
	selectedShippingMethods: (cartId: CartId) => ['cart', cartId, 'shipping', 'methods'],
	selectedAddresses: (cartId: CartId) => ['cart', cartId, 'shipping', 'addresses'],
	submission: (cartId: CartId) => ['cart', cartId, 'submission'],
} as const;

export const cartQueryOptions = {
	types: queryOptions({
		queryKey: cartQueryKeys.types,
		queryFn: ({ signal }) => getOrderTypes(signal),
		staleTime: Infinity,
	}),

	saved: queryOptions({
		queryKey: cartQueryKeys.saved,
		queryFn: () => getSavedBulkOrders(),
		staleTime: 5 * 60 * 1000,
	}),

	availableShippingMethods: queryOptions({
		queryKey: cartQueryKeys.availableShippingMethods,
		queryFn: () => getAvailableShippingOptions(),
		staleTime: Infinity,
	}),

	cartId: (enabled: boolean) =>
		queryOptions({
			queryKey: cartQueryKeys.cartId,
			queryFn: ({ signal }) => getOrCreateBulkOrder(signal),
			enabled,
		}),

	selectedLocations: (cartId: CartId) =>
		queryOptions({
			queryKey: cartQueryKeys.selectedLocations(cartId),
			queryFn: ({ signal }) => getLocationSelections(cartId, signal),
			initialData: [],
		}),

	selectedLocationsPlantCount: (cartId: CartId) =>
		queryOptions({
			queryKey: cartQueryKeys.selectedLocationsPlantCount(cartId),
			queryFn: ({ signal }) => getSelectedLocationsPlantCount(cartId, signal),
			initialData: 0,
		}),

	selectedLocationsPrograms: (cartId: CartId) =>
		queryOptions({
			queryKey: cartQueryKeys.selectedLocationsPrograms(cartId),
			queryFn: ({ signal }) => getSelectedPrograms(cartId, signal),
			initialData: [],
		}),

	selectedProducts: (cartId: CartId) =>
		queryOptions({
			queryKey: cartQueryKeys.selectedProducts(cartId),
			queryFn: ({ signal }) => getProductSelections(cartId, signal),
			initialData: [],
		}),

	selectedQuantities: ({
		cartId,
		excludeZeroQuantity = false,
		enabled = true,
	}: {
		cartId: CartId;
		excludeZeroQuantity: boolean;
		enabled: boolean;
	}) =>
		queryOptions({
			queryKey: cartQueryKeys.selectedQuantities(
				cartId,
				excludeZeroQuantity ? 'exclude-zero' : 'include-zero',
			),
			queryFn: ({ signal }) =>
				getOrderItems(
					{
						bulkOrderId: cartId,
						areZeroQuantityLineItemsExcluded: excludeZeroQuantity,
					},
					signal,
				),
			enabled,
			initialData: {
				bulkOrderId: cartId,
				locationOrderItems: [],
				isOrderModifiedByValidation: false,
				bulkOrderItemsCount: 0,
				totalQuantityCount: 0,
				referenceNumber: null,
				salesOrderNumber: null,
			},
		}),

	selectedQuantitiesCount: ({ cartId, enabled }: { cartId: CartId; enabled: boolean }) =>
		queryOptions({
			queryKey: cartQueryKeys.selectedQuantitiesCount(cartId),
			queryFn: ({ signal }) => getItemsUpdatedCount(cartId, signal),
			enabled,
			initialData: 0,
		}),

	selectedAddresses: (cartId: CartId) =>
		queryOptions({
			queryKey: cartQueryKeys.selectedAddresses(cartId),
			queryFn: ({ signal }) => getSelectedAddresses(cartId, signal),
			initialData: [],
		}),
} as const;
