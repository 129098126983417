import axios from 'axios';

import { subprogramCustomerSchema, subprogramSchema } from '@apple/features/subprogram/models';
import { countryWithCulturesSchema } from '@apple/utils/globalization';
import type {
	Subprogram,
	SubProgramCustomer,
	SubProgramKey,
	SubprogramRequest,
} from '@apple/features/subprogram/models';
import type { CountryWithCultures } from '@apple/utils/globalization';

export async function getSubprogram(customerId: number, countryCode: string): Promise<Subprogram> {
	const response = await axios.get<Subprogram>(
		`/api/management/subprograms/${customerId}/${countryCode}`,
	);

	return subprogramSchema.parse(response.data);
}

export async function getAllSubprograms(): Promise<Subprogram[]> {
	const response = await axios.get<Subprogram[]>('/api/subprograms/all');

	return subprogramSchema.array().parse(response.data);
}

export async function saveSubprogram(subprogram: SubprogramRequest): Promise<void> {
	await axios.post('/api/management/subprograms', subprogram);
}

export async function getAllCustomersForProgram(programId: number): Promise<SubProgramCustomer[]> {
	const response = await axios.get<SubProgramCustomer[]>(
		`/api/subprograms/program/${programId}/customers`,
	);

	return subprogramCustomerSchema.array().parse(response.data);
}

export async function getAvailableCountriesForProgram(
	programId: number,
): Promise<CountryWithCultures[]> {
	const response = await axios.get<CountryWithCultures>(
		`/api/subprograms/program/${programId}/available-countries`,
	);

	return countryWithCulturesSchema.array().parse(response.data);
}

export async function deleteSubprogram(subprogramKeys: SubProgramKey[]): Promise<void> {
	await axios.request({
		method: 'delete',
		url: '/api/management/subprograms',
		data: subprogramKeys,
	});
}
