import { z } from 'zod';

import { dateTimeSchema } from '@apple/utils/globalization';

import { sortedImageSchema } from './image';
import type { SortedImage } from './image';

export type ProductKey = z.infer<typeof productKeySchema>;
export const productKeySchema = z.string();

/** @see Manzanita.Web.Models.ProductModel */
export interface ProductModel {
	itemId: ProductKey;
	title: string;
	sku: string;
	unitOfMeasure: string;
	itemState: string;
	description: string;
	images: SortedImage[];
	externalUrl?: string | null;
}

/** @see Manzanita.Web.Models.ProductModel */
export const productModelSchema = z.object({
	itemId: productKeySchema,
	title: z.string(),
	sku: z.string(),
	unitOfMeasure: z.string(),
	itemState: z.string(),
	description: z.string(),
	images: z.array(sortedImageSchema),
	externalUrl: z.string().optional().nullable(),
}) satisfies z.ZodType<ProductModel>;

/** @see Manzanita.Web.Models.ProductListingModel */
export interface ProductListingModel extends ProductModel {
	requiresApproval: boolean;
	estimatedInStockDate?: string | null;
	beginDate?: string | Date | null;
	endDate?: string | Date | null;
	deleted: boolean;
	showInventory: boolean;
	administratorOnly: boolean;
	showInventoryStatus: boolean;
	updatedDate?: string | Date | null;
	quantityLimit?: number | null;
	isBackorderable: boolean;
	unitPrice: string;
	numericUnitPrice: number;
	displayString: string;
}

/** @see Manzanita.Web.Models.ProductListingModel */
export const productListingModelSchema = productModelSchema.extend({
	requiresApproval: z.boolean(),
	estimatedInStockDate: z.string().nullable(),
	beginDate: dateTimeSchema.nullable(),
	endDate: dateTimeSchema.nullable(),
	deleted: z.boolean(),
	showInventory: z.boolean(),
	administratorOnly: z.boolean(),
	showInventoryStatus: z.boolean(),
	updatedDate: dateTimeSchema.nullable(),
	quantityLimit: z.number().nullable(),
	isBackorderable: z.boolean(),
	unitPrice: z.string(),
	numericUnitPrice: z.number(),
	displayString: z.string(),
}) satisfies z.ZodType<ProductListingModel>;

/** @see Manzanita.Web.ApiControllers.AutoCompleteApiController */
export interface ProductAutocompleteRequest {
	searchText: string;
	partNumbers: string[];
	customerCode: string;
}

/** @see Manzanita.Web.ApiControllers.AutoCompleteApiController */
export const productAutocompleteRequestSchema = z.object({
	searchText: z.string(),
	partNumbers: z.array(z.string()),
	customerCode: z.string(),
}) satisfies z.ZodType<ProductAutocompleteRequest>;
