import { keepPreviousData, queryOptions } from '@tanstack/react-query';

import { getAllPlants, getPlant } from '@apple/features/plants/api/management';

import { getPlants } from '../api/api';

export const plantQueryKeys = {
	plants: ['plants'],
	odata: ['plant', 'odata'],
	plant: (warehouseLocationId: string) => ['plant', warehouseLocationId],
};

export const plantQueryOptions = {
	plants: queryOptions({
		queryKey: plantQueryKeys.plants,
		queryFn: () => getPlants(),
		placeholderData: keepPreviousData,
		staleTime: Infinity,
	}),
	plant: (warehouseLocationId: string) =>
		queryOptions({
			queryKey: plantQueryKeys.plant(warehouseLocationId),
			queryFn: () => getPlant(warehouseLocationId),
		}),
	odata: queryOptions({
		queryKey: plantQueryKeys.odata,
		queryFn: getAllPlants,
	}),
};
