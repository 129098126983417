import { keepPreviousData, queryOptions } from '@tanstack/react-query';

import { getAllAnnouncements, getAnnouncementById } from '../api/management';

export const announcementQueryKeys = {
	announcement: (announcementId: string) => ['announcement', announcementId],
	announcements: () => ['announcement', 'list'],
};

export const announcementQueries = {
	getAnnouncementById: (announcementId: string) =>
		queryOptions({
			queryKey: announcementQueryKeys.announcement(announcementId),
			queryFn: () => getAnnouncementById(announcementId),
			placeholderData: keepPreviousData,
			throwOnError: true,
		}),

	getAllAnnouncements: () =>
		queryOptions({
			queryKey: announcementQueryKeys.announcements(),
			queryFn: () => getAllAnnouncements(),
			placeholderData: keepPreviousData,
			throwOnError: true,
		}),
};
