import { useQueries } from '@tanstack/react-query';

import { deleteLocationAccessRequest, saveAccessRequests } from '../api/location-access-request';
import {
	locationAccessLocationsQueryOptions,
	locationAccessProgramsQueryOptions,
} from '../queries/location-access.queries';
import type { LocationId } from '../models/location';
import type {
	UserLocationAccessRequests,
	UserProgramAssociations,
} from '../models/location-access';

type LocationAccessResponse =
	| { isLoading: true }
	| {
			isLoading: false;
			programs: UserProgramAssociations;
			locations: UserLocationAccessRequests;
			deleteLocation: (locationId: LocationId, removeAccess: boolean) => Promise<void>;
			requestLocationsApproval: (locationIds: LocationId[]) => Promise<void>;
			error: undefined;
	  }
	| { isLoading: false; error: Error };

export function useLocationAccess(
	fetchProgramAccess: boolean,
	fetchLocationAccess: boolean,
): LocationAccessResponse {
	const [programs, locations] = useQueries({
		queries: [locationAccessProgramsQueryOptions, locationAccessLocationsQueryOptions],
	});

	const isLoading = programs.isLoading || locations.isLoading;

	if (isLoading) {
		return { isLoading: true };
	}

	const error = programs.error ?? locations.error;

	if (error) {
		return { isLoading: false, error };
	}

	if (fetchProgramAccess && !programs.data) {
		const error = new Error('Failed to get user program associations.');
		return { isLoading: false, error };
	}

	if (fetchLocationAccess && !locations.data) {
		const error = new Error('Failed to get user location access requests.');
		return { isLoading: false, error };
	}

	async function deleteLocation(locationId: LocationId, removeAccess: boolean): Promise<void> {
		await deleteLocationAccessRequest(locationId, removeAccess);
		await locations.refetch();
	}

	async function requestLocationsApproval(locationIds: LocationId[]): Promise<void> {
		await saveAccessRequests(locationIds);
		await locations.refetch();
	}

	return {
		programs: programs.data!,
		locations: locations.data!,
		isLoading,
		deleteLocation,
		requestLocationsApproval,
		error: undefined,
	};
}
