import { useMemo } from 'react';
import { Box, Button, Center, Indicator, rem, Timeline } from '@mantine/core';

import { AcknowledgedAnnouncementIcon, ActiveAnnouncementIcon } from '@apple/assets/icons';
import { useTranslation } from '@apple/lib/i18next';
import { FormattedDate } from '@apple/utils/globalization/index';
import { useLogger } from '@apple/utils/logging/useLogger';

import { useAcknowledgeAnnouncement } from '../hooks/useAcknowledgeAnnouncement';
import { useUserAnnouncements } from '../hooks/useUserAnnouncements';
import { openAnnouncementModal } from './AnnouncementModal';
import type { Announcement, AnnouncementList } from '../models/announcement';

export function AnnouncementMenuContent() {
	const { t } = useTranslation('common');
	const acknowledgeAnnouncement = useAcknowledgeAnnouncement();
	const userAnnouncements = useUserAnnouncements();

	const sortedAnnouncements = useMemo(() => {
		if (!userAnnouncements.data) {
			return {
				announcements: [],
				totalCount: 0,
				unreadCount: 0,
			} satisfies AnnouncementList;
		}

		return {
			...userAnnouncements.data,
			announcements: userAnnouncements.data.announcements.sort((a, b) => {
				if (a.active !== b.active) {
					return Number(b.active) - Number(a.active);
				}
				return b.createdDate.getTime() - a.createdDate.getTime();
			}),
		} satisfies AnnouncementList;
	}, [userAnnouncements.data]);

	useLogger({
		name: 'AnnouncementMenuContent',
		props: [{ sortedAnnouncements }],
		log: 'announcement',
	});

	function handleOpenAnnouncement(announcement: Announcement) {
		openAnnouncementModal({
			t,
			announcement,
			onAcknowledge: () => {
				acknowledgeAnnouncement.mutate(announcement.contentId);
			},
		});
	}

	return (
		<>
			{sortedAnnouncements.announcements.length === 0 ? (
				<Center
					mih={200}
					children={t('pagination.empty')}
					data-testid='empty-announcements-label'
				/>
			) : (
				<Timeline
					active={sortedAnnouncements.announcements.findLastIndex(x => x.active)}
					bulletSize={32}
					lineWidth={2}
					styles={{
						item: {
							marginTop: rem(10),
						},
						itemBody: {
							paddingLeft: rem(6),
							paddingRight: rem(32),
						},
					}}
				>
					{sortedAnnouncements.announcements.map(announcement => (
						<Timeline.Item
							key={announcement.contentId}
							bullet={
								announcement.active ? (
									<ActiveAnnouncementIcon size='70%' />
								) : announcement.acknowledged ? (
									<AcknowledgedAnnouncementIcon size='70%' />
								) : null
							}
							c={announcement.active ? 'primary' : 'dimmed'}
							title={
								<Indicator
									inline
									size={9}
									position='middle-end'
									disabled={announcement.acknowledged}
									offset={-8}
								>
									<Button
										variant='subtle'
										p='xs'
										onClick={() => handleOpenAnnouncement(announcement)}
										c={announcement.acknowledged ? 'dimmed' : 'primary'}
										fw={announcement.active ? 'bold' : 'normal'}
										maw='300px'
									>
										<Box
											style={{
												overflow: 'hidden',
												textOverflow: 'ellipsis',
												whiteSpace: 'nowrap',
												maxWidth: '100%',
											}}
										>
											{announcement.title}
										</Box>
									</Button>
								</Indicator>
							}
						>
							<FormattedDate
								size='xs'
								c='dimmed'
								value={announcement.createdDate}
								humanize
								pl={'sm'}
							/>
						</Timeline.Item>
					))}
				</Timeline>
			)}
		</>
	);
}
