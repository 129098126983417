import { z } from 'zod';

import { manzanitaProductSchema } from '@apple/features/product/models/management';
import { emptyOrNullableDateTimeSchema } from '@apple/utils/globalization';
import type { Product } from '@apple/features/product/models/management';

import {
	programAssociationSchema,
	subProgramAssociationSchema,
	userRoleAssociationSchema,
} from './associations';
import type {
	ProgramAssociation,
	SubProgramAssociation,
	UserRoleAssociation,
} from './associations';

/**
 * @see Manzanita.Data.Manzanita.ManzanitaProduct
 * @see AppleBrandedPrograms.Catalog.AppleBrandedProduct
 */
export interface AppleBrandedProduct extends Product {
	quantityLimit: number | null;
	customerGroupAssociations: ProgramAssociation[];
	customerGroupCountryAssociations: SubProgramAssociation[];
	quantityLimits: UserRoleProductQuantityLimit[];
	userRoleAssociations: UserRoleAssociation[];
}

/** AppleBrandedPrograms.Catalog.QuantityLimits.UserRoleProductQuantityLimit */
export interface UserRoleProductQuantityLimit {
	itemId: string;
	roleId: number;
	maxLimit: number;
	startDate: Date | '' | null;
	endDate: Date | '' | null;
	updatedDate?: Date | '' | null;
	isDeleted?: boolean;
}

export const userRoleProductQuantityLimitSchema = z.object({
	itemId: z.string(),
	roleId: z.number(),
	maxLimit: z.number(),
	startDate: emptyOrNullableDateTimeSchema,
	endDate: emptyOrNullableDateTimeSchema,
	updatedDate: emptyOrNullableDateTimeSchema.optional(),
	isDeleted: z.boolean().optional(),
}) as z.ZodType<UserRoleProductQuantityLimit>;

export const appleBrandedProductSchema = manzanitaProductSchema.and(
	z.object({
		quantityLimit: z.coerce.number().nullable(),
		customerGroupAssociations: z.array(programAssociationSchema),
		customerGroupCountryAssociations: z.array(subProgramAssociationSchema),
		quantityLimits: z.array(userRoleProductQuantityLimitSchema),
		userRoleAssociations: z.array(userRoleAssociationSchema),
	}),
) satisfies z.ZodType<AppleBrandedProduct>;
