import { memo } from 'react';
import { Button, NativeSelect, Popover, Select } from '@mantine/core';
import { useFocusTrap, useOs } from '@mantine/hooks';
import type { ButtonProps } from '@mantine/core';

import { ChevronDown } from '@apple/assets/icons';
import type { IconElement } from '@apple/assets/icons';

interface FilterBarDropdownProps extends ButtonProps {
	data: { value: string; label: string }[];
	onOptionSubmit: (value: string) => void;
	targetIcon: IconElement;
	placeholder?: string;
	buttonVariant?: 'transparent' | 'default';
	showRightSection?: boolean;
	testId?: string;
}

export const FilterBarDropdown = memo<FilterBarDropdownProps>(
	({
		onOptionSubmit,
		data,
		targetIcon: TargetIcon,
		placeholder,
		buttonVariant,
		testId,
		showRightSection = true,
		...buttonProps
	}: FilterBarDropdownProps) => {
		const focusTrapRef = useFocusTrap();
		const iconColor =
			buttonVariant === 'transparent'
				? 'var(--mantine-color-gray-text)'
				: 'var(--mantine-primary-color-contrast)';
		const isMobile = useOs();

		return (
			<Popover position='bottom-start' shadow='md'>
				<Popover.Target>
					<Button
						p={0}
						maw='5em'
						variant={buttonVariant}
						leftSection={<TargetIcon c={iconColor} />}
						rightSection={showRightSection && <ChevronDown c={iconColor} />}
						data-testid={testId}
						{...buttonProps}
					/>
				</Popover.Target>
				<Popover.Dropdown>
					{isMobile === 'ios' || isMobile === 'android' ? (
						<NativeSelect
							ref={focusTrapRef}
							w='100%'
							data={data}
							onChange={event => onOptionSubmit(event.currentTarget.value)}
						/>
					) : (
						<Select
							ref={focusTrapRef}
							width='100%'
							placeholder={placeholder}
							dropdownOpened
							data={data}
							onChange={(value: string | null) => onOptionSubmit(value ?? '')}
						/>
					)}
				</Popover.Dropdown>
			</Popover>
		);
	},
);

FilterBarDropdown.displayName = 'FilterBarDropdown';
