import { useMutation, useQueryClient } from '@tanstack/react-query';

import { resetBulkOrder } from '../api';
import { cartQueryKeys } from '../queries';

export function useResetCart() {
	const queryClient = useQueryClient();
	return useMutation({
		mutationKey: ['cart', 'reset'],
		mutationFn: async () => await resetBulkOrder(),
		onSuccess: async () => {
			await queryClient.invalidateQueries({
				queryKey: cartQueryKeys.cartId,
			});
		},
	});
}
