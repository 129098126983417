import { useQueries } from '@tanstack/react-query';

import { globalizationQueryOptions } from '../queries';
import type { CountryOption, CurrencyOption, LanguageOption, TimeZoneOption } from '../models';

type GlobalizationResponse =
	| { isLoading: true }
	| {
			isLoading: false;
			timeZones: TimeZoneOption[];
			countries: CountryOption[];
			currencies: CurrencyOption[];
			languages: LanguageOption[];
			error: undefined;
	  }
	| { isLoading: false; error: Error };

export function useGlobalization(): GlobalizationResponse {
	const [timeZones, countries, currencies, languages] = useQueries({
		queries: [
			globalizationQueryOptions.timeZones,
			globalizationQueryOptions.countries,
			globalizationQueryOptions.globalizationCurrencies,
			globalizationQueryOptions.languages,
		],
	});

	const isLoading =
		timeZones.isLoading || countries.isLoading || currencies.isLoading || languages.isLoading;

	if (isLoading) {
		return { isLoading: true };
	}

	const error = timeZones.error ?? countries.error ?? currencies.error ?? languages.error;

	if (error) {
		return { isLoading: false, error };
	}

	if (!timeZones.data) {
		throw new Error('Failed to get globalization time zones.');
	}

	if (!countries.data) {
		throw new Error('Failed to get globalization countries.');
	}

	if (!currencies.data) {
		throw new Error('Failed to get globalization currencies.');
	}

	if (!languages.data) {
		throw new Error('Failed to get globalization languages.');
	}

	return {
		timeZones: timeZones.data,
		countries: countries.data,
		currencies: currencies.data,
		languages: languages.data,
		isLoading,
		error: undefined,
	};
}
