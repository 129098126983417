import axios from 'axios';
import type { AxiosProgressEvent } from 'axios';

import { userExcelResultSchema, userSchema } from '../models/management';
import type { User, UserExcelResult } from '../models/management';

/** @see AppleBrandedPrograms.Web.Features.Profiles.UserManagementController */
export async function getUser(username: string): Promise<User> {
	const data = (await axios.get<User>(`/api/management/users/${encodeURIComponent(username)}/`))
		.data;
	return userSchema.parse(data);
}

/** @see AppleBrandedPrograms.Web.Features.Profiles.UserManagementController */
export async function saveUser(user: User): Promise<void> {
	await axios.put('/api/management/users/save', user);
}

/** @see AppleBrandedPrograms.Web.Features.Profiles.UserManagementController */
export async function saveUsers(users: User[], auditComment?: string): Promise<void> {
	await axios.post('/api/management/users/save-multiple', users, {
		headers: auditComment ? { ChangeComment: auditComment } : undefined,
		params: { isSpa: true },
	});
}

/** @see AppleBrandedPrograms.Web.Features.Profiles.UserManagementController */
export async function addUser(user: User): Promise<void> {
	await axios.post('/api/management/users/add', user, { params: { isSpa: true } });
}

/** @see AppleBrandedPrograms.Web.Features.Profiles.UserManagementController */
export async function unlockUser(username: string): Promise<void> {
	await axios.post(`/api/management/users/${encodeURIComponent(username)}/unlock`);
}

/** @see AppleBrandedPrograms.Web.Features.Profiles.UserManagementController */
export async function deleteUser(username: string): Promise<void> {
	await axios.delete('/api/management/users', { params: { username } });
}

/** @see AppleBrandedPrograms.Web.Features.Profiles.UserManagementController */
export async function deleteUsers(usernames: string[], auditComment?: string): Promise<void> {
	if (usernames.length === 0) {
		return;
	}

	await axios.post('/api/management/users/delete-multiple', usernames, {
		headers: auditComment ? { ChangeComment: auditComment } : undefined,
	});
}

/** @see AppleBrandedPrograms.Web.Features.Profiles.UsersExcelController */
export function getUserManagementTemplateUrl(): string {
	return '/api/management/users/bulk/excel/generate';
}

/** @see AppleBrandedPrograms.Web.Features.Profiles.UsersExcelController */
export async function uploadUsers(
	file: File,
	onUploadProgress?: (event: AxiosProgressEvent) => void,
): Promise<UserExcelResult> {
	const response = await axios.post<unknown>('/api/management/users/bulk/excel', file, {
		headers: { 'Content-Type': file.type },
		onUploadProgress,
	});

	return userExcelResultSchema.parse(response.data);
}
