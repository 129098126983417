import axios from 'axios';

import { currencyConversionSchema } from './models';
import type { CurrencyConversion, CurrencyConversionDeleteItem } from './models';

/** Service wrapper for Manzanita.Web.Areas.Admin.ApiControllers.CurrencyConversionManagementApiController.GetByIsoCodes */
export async function getCurrencyConversion(
	fromCurrencyIsoCode: string,
	toCurrencyIsoCode: string,
): Promise<CurrencyConversion> {
	const data = (
		await axios.get<unknown>(
			`/api/management/currency-conversions/${fromCurrencyIsoCode}/${toCurrencyIsoCode}`,
		)
	).data;
	return currencyConversionSchema.parse(data);
}

/** Service wrapper for Manzanita.Web.Areas.Admin.ApiControllers.CurrencyConversionManagementApiController.Post */
export async function saveCurrencyConversion(request: CurrencyConversion): Promise<void> {
	await axios.post('/api/management/currency-conversions', request);
}

/** Service wrapper for Manzanita.Web.Areas.Admin.ApiControllers.CurrencyConversionManagementApiController.Delete */
export async function deleteCurrencyConversions(
	currencyConversions: CurrencyConversionDeleteItem[],
): Promise<void> {
	await axios.delete('/api/management/currency-conversions', { data: currencyConversions });
}

/** Service wrapper for Manzanita.Web.Areas.Admin.ApiControllers.CurrencyConversionManagementApiController.GetActiveConversions */
export async function getActiveCurrencyConversions(): Promise<CurrencyConversion[]> {
	const response = await axios.get('/api/management/currency-conversions/active');
	return currencyConversionSchema.array().parse(response.data);
}
