import { useQuery } from '@tanstack/react-query';

import { locationQueries } from '../queries/location-listing.queries';
import type { Location, LocationId } from '../models/location';

type LocationsResponse =
	| { isLoading: true }
	| {
			isLoading: false;
			locations: Location[];
			refresh: () => Promise<void>;
			error: undefined;
	  }
	| { isLoading: false; error: Error };

export function useLocations(locationIds: LocationId[]): LocationsResponse {
	const { isLoading, data, error, refetch } = useQuery(
		locationQueries.getLocationsByIds(locationIds),
	);

	if (isLoading) {
		return { isLoading: true };
	}

	if (error) {
		return { isLoading: false, error };
	}

	if (!data) {
		const error = new Error('Failed to get locations information.');
		return { isLoading: false, error: error };
	}

	async function refresh() {
		await refetch();
	}

	return {
		locations: data,
		isLoading,
		refresh,
		error: undefined,
	};
}
