import { useCallback, useMemo } from 'react';
import { Box, Button, Card, Center, Divider, Group, rem, Stack, Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { useNavigate } from '@tanstack/react-router';
import { getLogger } from 'loglevel';

import { icons } from '@apple/assets';
import { useTranslation } from '@apple/lib/i18next';
import { MenuPopover } from '@apple/ui/shell/components/MenuPopover';
import { useLogger } from '@apple/utils/logging/useLogger';
import type { CartId } from '@apple/features/cart/models/cart-id';

import { useCartIdContext } from '../../cart/contexts/cart-id';
import { SavedCartsList } from '../components/SavedCartList';
import { useCartItemCount } from '../hooks/useCartItemCount';
import { useResetCart } from '../hooks/useResetCart';
import { useSavedCarts } from '../hooks/useSavedCarts';

const log = getLogger('cart-menu');

export function CartMenu() {
	const { t } = useTranslation('shop');
	const navigate = useNavigate();
	const cartId = useCartIdContext();
	const { savedCarts, activeCart, deleteSavedCart, saveCart } = useSavedCarts();
	const resetCart = useResetCart();
	const cartItemCount = useCartItemCount();

	const cartList = useMemo(
		() => savedCarts.data?.filter(x => x.bulkOrderId !== cartId) ?? [],
		[cartId, savedCarts],
	);

	useLogger({
		name: 'CartMenu',
		props: [
			{
				cartId,
				cartItemCount,
				cartList,
				savedCarts,
				activeCart,
				deleteSavedCart,
				saveCart,
			},
		],
		log,
	});

	const handleSwitchCart = useCallback(
		(cartId: CartId) => {
			if (cartItemCount.isSuccess && cartItemCount.data === 0) {
				activeCart.mutate(cartId, {
					onSuccess: () =>
						void navigate({ to: '/cart/$step', params: { step: 'review' } }),
				});
				return;
			}

			modals.openConfirmModal({
				modalId: 'switch-cart',
				title: t('cart.switchModal.title'),
				children: <Text>{t('cart.switchModal.description')}</Text>,
				labels: {
					confirm: t('cart.switchModal.continue'),
					cancel: t('common:buttons.cancel'),
				},
				onConfirm: () =>
					activeCart.mutate(cartId, {
						onSuccess: () =>
							void navigate({ to: '/cart/$step', params: { step: 'review' } }),
					}),
			});
		},
		[t, activeCart, navigate, cartItemCount.data, cartItemCount.isSuccess],
	);

	const handleClearCart = useCallback(() => {
		if (cartItemCount.isSuccess && cartItemCount.data === 0) {
			resetCart.mutate(undefined, {
				onSuccess: () => void navigate({ to: '/cart' }),
			});
			return;
		}

		modals.openConfirmModal({
			modalId: 'clear-cart',
			title: t('cart.clearModal.title'),
			children: <Text>{t('cart.clearModal.description')}</Text>,
			labels: {
				confirm: t('cart.clearModal.continue'),
				cancel: t('common:buttons.cancel'),
			},
			onConfirm: () =>
				resetCart.mutate(undefined, {
					onSuccess: () => void navigate({ to: '/cart' }),
				}),
		});
	}, [t, navigate, cartItemCount.data, cartItemCount.isSuccess, resetCart]);

	const handleDeleteSavedCart = useCallback(
		(cartId: CartId) => {
			modals.openConfirmModal({
				modalId: 'remove-saved-cart',
				title: t('cart.removeModal.title'),
				children: <Text>{t('cart.removeModal.description')}</Text>,
				labels: {
					confirm: t('cart.removeModal.continue'),
					cancel: t('common:buttons.cancel'),
				},
				onConfirm: () => deleteSavedCart.mutate(cartId),
			});
		},
		[t, deleteSavedCart],
	);

	const handleViewCart = () => {
		return void navigate({ to: '/cart/$step', params: { step: 'review' } });
	};

	function handleSaveCart() {
		saveCart.mutate(
			{
				bulkOrderId: cartId ?? '',
				purchaseOrderNumber: '',
				comments: '',
			},
			{
				onSuccess: () => {
					resetCart.mutate(undefined, {
						onSuccess: () => {
							notifications.show({
								title: t('cart.save.success.title'),
								message: t('cart.save.success.description'),
								icon: <icons.Success />,
								autoClose: 5000,
							});
							void navigate({ to: '/cart' });
						},
						onError: err => {
							log.error(err);
							notifications.show({
								title: t('cart.save.fail.title'),
								message: t('cart.save.fail.description'),
								icon: <icons.Warning />,
								color: 'red',
								autoClose: 5000,
							});
						},
					});
				},
				onError: err => {
					log.error(err);
					notifications.show({
						title: t('cart.save.fail.title'),
						message: t('cart.save.fail.description'),
						icon: <icons.Warning />,
						color: 'red',
						autoClose: 5000,
					});
				},
			},
		);
	}

	return (
		<MenuPopover
			icon={icons.ShoppingBag}
			iconTestId='cart-menu'
			loading={savedCarts.isLoading || cartItemCount.isLoading}
			tooltipLabel={t('cart.labels.cart')}
			indicatorCount={cartItemCount.data ?? 0}
			indicatorDisabled={(cartItemCount.data ?? 0) <= 0}
		>
			<Card.Section
				m={0}
				p='md'
				style={{
					borderRadius: `${rem(24)} ${rem(24)} 0 0`,
				}}
			>
				<Group align='center' justify='space-between'>
					<Button
						fw='bold'
						onClick={handleSaveCart}
						variant='transparent'
						disabled={(cartItemCount.data ?? 0) === 0}
					>
						{t('cart.buttons.saveCart')}
					</Button>
					<Text fw='bold'>{t('cart.labels.cart')}</Text>
					<Button
						fw='bold'
						onClick={handleClearCart}
						variant='transparent'
						c='red'
						disabled={(cartItemCount.data ?? 0) === 0}
					>
						{t('cart.buttons.clearCart')}
					</Button>
				</Group>
			</Card.Section>

			<Card.Section m='xs'>
				<Group justify='center' mt='xs'>
					{(cartItemCount.data ?? 0 > 0) ? (
						<Stack>
							<Box>
								<Text>
									{t('cart.labels.itemsCountMessage', {
										itemsCount: cartItemCount.data ?? 0,
									})}
								</Text>
							</Box>
							<Button w={'14em'} onClick={handleViewCart}>
								{t('cart.buttons.viewCart')}
							</Button>
						</Stack>
					) : (
						<Box mt='xs'>
							<Text>{t(`cart.labels.noItemsMessage`)}</Text>
						</Box>
					)}
				</Group>
			</Card.Section>

			{cartList.length > 0 && (
				<Card.Section m='xs'>
					<Center>
						<Divider w={150} />
					</Center>
					<SavedCartsList
						savedCarts={cartList}
						onClick={handleSwitchCart}
						onRemoveClick={handleDeleteSavedCart}
					/>
				</Card.Section>
			)}
		</MenuPopover>
	);
}
