import { memo } from 'react';
import { Group, Title } from '@mantine/core';
import type { GroupProps } from '@mantine/core';
import type { ReactNode } from 'react';

import { TOOLBAR_HEIGHT } from './toolbar-button-props';

export interface ToolbarProps {
	groupProps?: GroupProps;
	title?: string;
	height?: string;
	leftSection?: () => ReactNode;
	rightSection?: () => ReactNode;
}

export const Toolbar = memo(_Toolbar);
Toolbar.displayName = 'Toolbar';

function _Toolbar({
	groupProps,
	title,
	height = TOOLBAR_HEIGHT,
	leftSection,
	rightSection,
}: ToolbarProps) {
	return (
		<Group
			px='lg'
			py='xxs'
			align='flex-end'
			justify='space-between'
			mih={height}
			mah={height}
			w='100%'
			wrap='nowrap'
			{...groupProps}
		>
			<Group wrap='nowrap'>
				{leftSection && leftSection()}
				{title && (
					<Title order={5} fw={600}>
						{title}
					</Title>
				)}
			</Group>
			<Group gap={0} wrap='nowrap' style={{ zIndex: 150 }}>
				{rightSection && rightSection()}
			</Group>
		</Group>
	);
}
