import { createContext } from 'react';

import type { Profile } from '@apple/features/auth-channel/models/user';
import type { AuthContextBase } from '@apple/features/auth/contexts/auth-context';

export type AuthContext = AuthContextBase<Profile> & {
	canAccessMultipleLocations: boolean;
	sendForgotUsernameEmail: (email: string) => Promise<void>;
};

export const AuthContext = createContext<AuthContext | undefined>(undefined);
