import { queryOptions } from '@tanstack/react-query';

import {
	getAllCustomersForProgram,
	getAllSubprograms,
	getAvailableCountriesForProgram,
	getSubprogram,
} from './api';

export const subProgramQueryKeys = {
	all: ['subprograms'],
	subprogram: (customerId: number, countryCode: string) => [
		'subprogram',
		customerId,
		countryCode,
	],
	program: (programId: number) => ['program', programId],
	countries: (programId: number) => ['program', programId, 'countries'],
	customers: (programId: number) => ['program', programId, 'customers'],
} as const;

export const subProgramQueryOptions = {
	all: queryOptions({
		queryKey: subProgramQueryKeys.all,
		queryFn: () => getAllSubprograms(),
		initialData: [],
	}),
	subprogram: (customerId: number, countryCode: string) =>
		queryOptions({
			queryKey: subProgramQueryKeys.subprogram(customerId, countryCode),
			queryFn: () => getSubprogram(customerId, countryCode),
			staleTime: 5 * 60 * 1000,
		}),
	customers: (programId: number) =>
		queryOptions({
			enabled: !!programId,
			queryKey: subProgramQueryKeys.customers(programId),
			queryFn: () => getAllCustomersForProgram(programId),
			initialData: [],
		}),
	countries: (programId: number) =>
		queryOptions({
			enabled: !!programId,
			queryKey: subProgramQueryKeys.countries(programId),
			queryFn: () => getAvailableCountriesForProgram(programId),
			initialData: [],
		}),
};
