import { useCallback, useEffect, useState } from 'react';

import { useAuthContext } from '@apple/features/auth/hooks/useAuthContext';
import { useTranslation } from '@apple/lib/i18next';
import { DEFAULT_CULTURE } from '@apple/utils/globalization';
import type { ProfileDto } from '@apple/features/auth';

export type CurrentUserLanguage = {
	language: string;
	error: unknown;
	loading: boolean;
};

export function useCurrentUserLanguage(): CurrentUserLanguage {
	const auth = useAuthContext();
	const { i18n } = useTranslation();

	const [isLanguageChanging, setIsLanguageChanging] = useState(false);
	const [languageChangeError, setLanguageChangeError] = useState<Error | null>(null);

	const handleLanguageChange = useCallback(
		async (data: ProfileDto) => {
			const languageCode = data.language ?? DEFAULT_CULTURE;

			if (i18n.language !== languageCode) {
				setIsLanguageChanging(true);
				setLanguageChangeError(null);

				try {
					await i18n.changeLanguage(languageCode);
				} catch (error) {
					setLanguageChangeError(
						error instanceof Error ? error : new Error('Failed to change language'),
					);
				} finally {
					setIsLanguageChanging(false);
				}
			}
		},
		[i18n],
	);

	useEffect(() => {
		if (auth.authenticated && auth.profile && auth.profile.language !== i18n.language) {
			void handleLanguageChange(auth.profile);
		}
	}, [auth, handleLanguageChange, i18n.language]);

	return {
		language: i18n.language,
		error: languageChangeError,
		loading: isLanguageChanging,
	};
}
