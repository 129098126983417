import { keepPreviousData, queryOptions } from '@tanstack/react-query';
import type { PaginationState } from '@tanstack/table-core';

import {
	getActiveCurrencyConversions,
	getCurrencyConversion,
} from '@apple/features/currencies/api';

import type { CurrencyConversionFilters } from './models';

export const currencyQueryKeys = {
	all: ['currency-conversion'],
	active: ['currency-conversion', 'active'],
	conversion: (fromCurrency: string, toCurrency: string) => [
		'currency-conversion',
		fromCurrency,
		toCurrency,
	],
	odata: (filters: CurrencyConversionFilters, pagination: PaginationState) => [
		'currency-conversion',
		'odata',
		filters,
		pagination,
	],
};

export const currencyQueries = {
	getConversion: (fromCurrency: string, toCurrency: string) =>
		queryOptions({
			queryKey: currencyQueryKeys.conversion(fromCurrency, toCurrency),
			queryFn: () => getCurrencyConversion(fromCurrency, toCurrency),
			placeholderData: keepPreviousData,
			throwOnError: true,
		}),
	getActiveConversions: () =>
		queryOptions({
			queryKey: currencyQueryKeys.active,
			queryFn: () => getActiveCurrencyConversions(),
			placeholderData: keepPreviousData,
			throwOnError: true,
		}),
};
