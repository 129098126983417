import { useCallback, useState } from 'react';
import { Box, LoadingOverlay, Table, Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';

import { icons } from '@apple/assets';
import { useTranslation } from '@apple/lib/i18next';
import type {
	Location,
	LocationAccessRequest,
	LocationId,
	UserLocationAssociation,
} from '@apple/features/location';

export type LocationsListProps = {
	assignedLocations: UserLocationAssociation[];
	requestedLocations: LocationAccessRequest[];
	newLocations: Location[];
	locations: Location[];
	deleteLocation: (locationId: LocationId, removeAccess: boolean) => Promise<void>;
	addLocation: (location: LocationId) => void;
};

export function LocationAssociationsList({
	assignedLocations: assignedLocations,
	requestedLocations,
	newLocations,
	locations,
	deleteLocation,
	addLocation,
}: LocationsListProps) {
	const { t } = useTranslation('profile');
	const [loading, setLoading] = useState(false);

	const fireDeleteLocation = useCallback(
		async (locationId: LocationId, removeAccess: boolean) => {
			try {
				setLoading(true);
				await deleteLocation(locationId, removeAccess);
			} catch {
				notifications.show({
					color: 'red',
					message: t('common:error.generic'),
					icon: <icons.Error />,
				});
			} finally {
				setLoading(false);
			}
		},
		[deleteLocation, t],
	);

	const handleRemoveLocation = useCallback(
		async (locationId: LocationId, removeAccess: boolean = false, appleId?: string) => {
			if (removeAccess) {
				modals.openConfirmModal({
					children: (
						<Text size='sm'>
							{t('locationAssociations.deleteConfirmation', { appleId })}
						</Text>
					),
					labels: {
						confirm: t('common:buttons.confirm'),
						cancel: t('common:buttons.cancel'),
					},
					onConfirm: () => void fireDeleteLocation(locationId, removeAccess),
				});
			} else {
				await fireDeleteLocation(locationId, removeAccess);
			}
		},
		[t, fireDeleteLocation],
	);

	const removeNewLocation = useCallback(
		(locationId: LocationId) => {
			addLocation(locationId);
		},
		[addLocation],
	);

	const locationRows = assignedLocations.map(location => {
		const locationInfo = locations.find(x => x.id === location.locationId);

		if (locationInfo === undefined) {
			return <Table.Tr key={location.locationId}></Table.Tr>;
		}

		return (
			<Table.Tr key={location.locationId}>
				<Table.Td>{locationInfo.appleId}</Table.Td>
				<Table.Td>{locationInfo.storeName}</Table.Td>
				<Table.Td>{locationInfo.retailerName}</Table.Td>
				<Table.Td>{locationInfo.storeCity}</Table.Td>
				<Table.Td>{locationInfo.storeStateOrProvince}</Table.Td>
				<Table.Td>{locationInfo.storeCountryCode}</Table.Td>
				<Table.Td>{t('locationAssociations.status.Approved')}</Table.Td>
				<Table.Td>
					{assignedLocations.length > 1 && (
						<icons.Trash
							variant='transparent'
							onClick={() =>
								void handleRemoveLocation(
									locationInfo.id,
									true,
									locationInfo.appleId,
								)
							}
							data-testid={`remove-location-${locationInfo.id}`}
						/>
					)}
				</Table.Td>
			</Table.Tr>
		);
	});

	const requestRows = requestedLocations.map(request => {
		const locationInfo = locations.find(x => x.id === request.locationId);

		if (locationInfo === undefined) return <Table.Tr key={request.locationId}></Table.Tr>;

		return (
			<Table.Tr key={request.locationId}>
				<Table.Td>{locationInfo.appleId}</Table.Td>
				<Table.Td>{locationInfo.storeName}</Table.Td>
				<Table.Td>{locationInfo.retailerName}</Table.Td>
				<Table.Td>{locationInfo.storeCity}</Table.Td>
				<Table.Td>{locationInfo.storeStateOrProvince}</Table.Td>
				<Table.Td>{locationInfo.storeCountryCode}</Table.Td>
				<Table.Td>{t(`locationAssociations.status.${request.status}`)}</Table.Td>
				<Table.Td>
					<icons.Trash
						variant='transparent'
						onClick={() => void handleRemoveLocation(locationInfo.id)}
						data-testid={`remove-location-${locationInfo.id}`}
						c='red'
					/>
				</Table.Td>
			</Table.Tr>
		);
	});

	const newLocationRows = newLocations.map(location => {
		return (
			<Table.Tr key={location.id}>
				<Table.Td>{location.appleId}</Table.Td>
				<Table.Td>{location.storeName}</Table.Td>
				<Table.Td>{location.retailerName}</Table.Td>
				<Table.Td>{location.storeCity}</Table.Td>
				<Table.Td>{location.storeStateOrProvince}</Table.Td>
				<Table.Td>{location.storeCountryCode}</Table.Td>
				<Table.Td>{t('locationAssociations.status.PendingSubmission')}</Table.Td>
				<Table.Td>
					<icons.Trash
						variant='transparent'
						onClick={() => removeNewLocation(location.id)}
						data-testid={`remove-location-${location.id}`}
						size='20'
						c='red'
					/>
				</Table.Td>
			</Table.Tr>
		);
	});

	return (
		<Box pos='relative'>
			<LoadingOverlay visible={loading} zIndex={1000} />
			<Table striped withTableBorder highlightOnHover variant='apple-table'>
				<Table.Thead>
					<Table.Tr>
						<Table.Th>{t('locationAssociations.headers.locationId')}</Table.Th>
						<Table.Th>{t('locationAssociations.headers.locationName')}</Table.Th>
						<Table.Th>{t('locationAssociations.headers.retailer')}</Table.Th>
						<Table.Th>{t('locationAssociations.headers.city')}</Table.Th>
						<Table.Th>{t('locationAssociations.headers.stateProvince')}</Table.Th>
						<Table.Th>{t('locationAssociations.headers.country')}</Table.Th>
						<Table.Th>{t('locationAssociations.headers.status')}</Table.Th>
						<Table.Th></Table.Th>
					</Table.Tr>
				</Table.Thead>
				<Table.Tbody>
					{newLocationRows}
					{locationRows}
					{requestRows}
				</Table.Tbody>
			</Table>
		</Box>
	);
}
