import type { PropsWithChildren } from 'react';

import { LoadingOverlay } from '@apple/ui/loading-overlay/LoadingOverlay';

import { AuthContext } from './auth-context';
import type { ProfileDto } from '../models/user';
import type { AuthContextBase } from './auth-context';

export function AuthProvider({
	children,
	auth,
	loading,
}: PropsWithChildren<{ auth: AuthContextBase<ProfileDto>; loading: boolean }>) {
	return (
		<AuthContext.Provider value={{ ...auth }}>
			<LoadingOverlay visible={loading} debounce={300} name='auth' />
			{!loading && children}
		</AuthContext.Provider>
	);
}
