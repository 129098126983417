import { createContext, useContext } from 'react';
import { getLogger } from 'loglevel';
import type { PropsWithChildren } from 'react';

import { LoadingOverlay } from '@apple/ui/loading-overlay';
import { useLogger } from '@apple/utils/logging/useLogger';
import type { CartId } from '@apple/features/cart/models/cart-id';

const CartIdContext = createContext<CartId | null>(null);
const log = getLogger('cart');

type Props = PropsWithChildren<{
	value: CartId | null;
	loading: boolean;
}>;

export function CartIdProvider({ value, loading, children }: Props) {
	useLogger({
		name: 'CartIdProvider',
		props: [{ cartId: value }],
		log,
	});

	return (
		<CartIdContext.Provider value={value}>
			<LoadingOverlay visible={loading} />
			{loading ? null : children}
		</CartIdContext.Provider>
	);
}

// eslint-disable-next-line react-refresh/only-export-components
export function useCartIdContext() {
	return useContext(CartIdContext);
}

// eslint-disable-next-line react-refresh/only-export-components
export function useCartId() {
	const ctx = useCartIdContext();

	if (ctx === null) {
		throw new Error('CartId has not been set in the CartId Context');
	}

	return ctx;
}
