import { zodResolver } from '@mantine/form';
import type { ZodObject, ZodRawShape, ZodTypeAny } from 'zod';

import type { AllKeysAreStrings } from '@apple/utils/type';

import { ValidatedForm } from './ValidatedForm';
import type { ValidatedFormProps } from './ValidatedForm';

type Props<T extends AllKeysAreStrings<T>, TSchema extends ZodObject<ZodRawShape>> = Omit<
	ValidatedFormProps<T>,
	'validate'
> & {
	schema: TSchema;
	schemaExtension?: (schema: TSchema) => ZodTypeAny;
};

export function ValidatedZodForm<
	T extends AllKeysAreStrings<T>,
	TSchema extends ZodObject<ZodRawShape>,
>({ schema, schemaExtension, ...rest }: Props<T, TSchema>) {
	return (
		<ValidatedForm<T>
			{...rest}
			validate={zodResolver(schemaExtension ? schemaExtension(schema) : schema)}
		/>
	);
}
