import { z } from 'zod';

import { associationSchema } from '@apple/features/product/models/associations';
import { programIdSchema } from '@apple/features/program/models';
import { countryCodeSchema } from '@apple/utils/globalization';
import type {
	CustomerGroupAssociation,
	ProductAssociation,
} from '@apple/features/product/models/associations';
import type { CountryCode } from '@apple/utils/globalization';

export interface ProgramAssociation extends CustomerGroupAssociation {}

export interface SubProgramAssociation extends ProductAssociation {
	customerGroupId: number;
	countryCode: CountryCode;
}

export interface UserRoleAssociation extends ProductAssociation {
	roleId: number;
}

export const programAssociationSchema = associationSchema.extend({
	customerGroupId: programIdSchema,
}) satisfies z.ZodType<ProgramAssociation>;

export const subProgramAssociationSchema = programAssociationSchema.extend({
	countryCode: countryCodeSchema,
}) satisfies z.ZodType<SubProgramAssociation>;

export const userRoleAssociationSchema = associationSchema.extend({
	roleId: z.number(),
}) satisfies z.ZodType<UserRoleAssociation>;
