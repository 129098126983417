import { z } from 'zod';

export type OrderStatus = string;

/** Manzanita.DataModels.OrderCommentStatus */
export enum OrderCommentStatus {
	Sent = 1,
	Received = 2,
}

/** Manzanita.DataModels.OrderCommentStatus */
export const orderCommentStatusSchema = z.nativeEnum(OrderCommentStatus);
