import { useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

export function useBreakpoints() {
	const theme = useMantineTheme();
	const xsOrSmaller = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
	const smOrSmaller = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
	const mdOrSmaller = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
	const lgOrSmaller = useMediaQuery(`(max-width: ${theme.breakpoints.lg})`);
	const xlOrSmaller = useMediaQuery(`(max-width: ${theme.breakpoints.xl})`);

	const xsOrLarger = useMediaQuery(`(min-width: ${theme.breakpoints.xs})`);
	const smOrLarger = useMediaQuery(`(min-width: ${theme.breakpoints.sm})`);
	const mdOrLarger = useMediaQuery(`(min-width: ${theme.breakpoints.md})`);
	const lgOrLarger = useMediaQuery(`(min-width: ${theme.breakpoints.lg})`);
	const xlOrLarger = useMediaQuery(`(min-width: ${theme.breakpoints.xl})`);

	return {
		xsOrSmaller,
		smOrSmaller,
		mdOrSmaller,
		lgOrSmaller,
		xlOrSmaller,

		xsOrLarger,
		smOrLarger,
		mdOrLarger,
		lgOrLarger,
		xlOrLarger,
	};
}
