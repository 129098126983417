import axios from 'axios';

import { productVisibilityRolesSchema, roleSchema } from '../models/roles';
import type { ProductVisibilityRoles, Role } from '../models/roles';

/**
 * @see AppleBrandedPrograms.Web.Features.Profiles.UserRoleListingApiController
 * @see AppleRetail.Web.Features.Profiles.UserRoleListingApiController
 */
export async function getRoles(): Promise<Role[]> {
	const response = await axios.get('/api/user-roles');
	return roleSchema.array().parse(response.data);
}

/**
 * @see AppleBrandedPrograms.Web.Features.Profiles.Controllers.UserRoleListingApiController
 * Should move to auth-channel when product visibility is refactored.
 * */
export async function getProductVisibilityRoles(): Promise<ProductVisibilityRoles> {
	const response = await axios.get('/api/user-roles/product-visibility');

	return productVisibilityRolesSchema.parse(response.data);
}
