import axios from 'axios';

import { plantSchema } from '@apple/features/plants/models/models';
import { encodeSlashes } from '@apple/utils/url';
import type { Plant } from '@apple/features/plants/models/models';

import {
	endOfLifeReplacementSchema,
	inventorySchema,
	warehousePriceSchema,
} from '../models/management';
import type {
	EndOfLifeReplacement,
	Inventory,
	ProductImageUploadResponse,
	WarehousePrice,
} from '../models/management';
import type { ProductKey } from '../models/product';

/**
 * Gets current inventory information for the given item.
 * @see Manzanita.Web.Features.Catalog.ProductsController
 */
export async function getInventory(itemId: string): Promise<Inventory[]> {
	const response = await axios.get(`/api/management/products/${encodeSlashes(itemId)}/inventory`);

	return inventorySchema.array().parse(response.data);
}

/**
 * Gets current price information for the given item.
 * @see Manzanita.Web.Features.Catalog.ProductsController
 */
export async function getPrices(itemId: string): Promise<WarehousePrice[]> {
	const response = await axios.get(`/api/management/products/${encodeSlashes(itemId)}/prices`);

	return warehousePriceSchema.array().parse(response.data);
}

/**
 * Gets all end of life records where the replacement SKU belongs to the given item.
 * @see Manzanita.Web.Features.Catalog.ProductsController
 */
export async function getEolReplacementRecords(itemId: string): Promise<EndOfLifeReplacement[]> {
	const response = await axios.get(
		`/api/management/products/${encodeSlashes(itemId)}/eol-replacements`,
	);

	return endOfLifeReplacementSchema.array().parse(response.data);
}

/**
 * Gets all plants available per inventory.
 * @see Manzanita.Web.Features.Catalog.ProductsController
 */
export async function getPlantsWithInventory(itemId: string): Promise<Plant[]> {
	const response = await axios.get(`/api/management/products/${encodeSlashes(itemId)}/plants`);

	return plantSchema.array().parse(response.data);
}

/**
 * Gets all SKUs in the system that are not assigned to a product in the catalog.
 * @see Manzanita.Web.Features.Catalog.ProductsController
 */
export async function getUnassignedSkus(): Promise<ProductKey[]> {
	const response = await axios.get<ProductKey[]>(
		'/api/management/products/inventory/unassigned-skus',
	);
	return response.data;
}

/**
 * Uploads product image
 * @see Manzanita.Web.Features.Catalog.ProductImagesController
 */
export async function uploadProductImage(
	file: File,
	itemId: string,
): Promise<ProductImageUploadResponse> {
	const body = new FormData();
	body.append('file0', file);
	body.append('itemId', itemId);

	const response = await axios.post<ProductImageUploadResponse>(
		'/api/management/products/images',
		body,
	);

	return response.data;
}
