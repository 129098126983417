import { useCallback, useState } from 'react';
import { Box, Button, Group, LoadingOverlay, Stack, Text, Title } from '@mantine/core';

import { icons } from '@apple/assets';
import { LocationAutocomplete, useLocations } from '@apple/features/location';
import { useTranslation } from '@apple/lib/i18next';
import type { Location, LocationId, UserLocationAccessRequests } from '@apple/features/location';

import { LocationAssociationsList } from './LocationAssociationsList';

export type LocationsAssociationsProps = {
	locationAccess: UserLocationAccessRequests;
	deleteLocation: (locationId: LocationId, removeAccess: boolean) => Promise<void>;
	requestLocationsApproval: (locationIds: LocationId[]) => Promise<void>;
};

export function LocationAssociations({
	locationAccess,
	deleteLocation,
	requestLocationsApproval,
}: LocationsAssociationsProps) {
	const { t } = useTranslation('profile');
	const [newLocations, setNewLocations] = useState<Location[]>([]);
	const [selectedLocation, setSelectedLocation] = useState<Location | undefined>();
	const locationIds = locationAccess.locations
		.map(x => x.locationId)
		.concat(locationAccess.requests.map(x => x.locationId))
		.concat(
			newLocations.map(l => {
				return l.id;
			}),
		);
	const locations = useLocations(locationIds);

	const addNewLocation = useCallback(() => {
		if (selectedLocation === undefined) return;
		if (!newLocations.some(l => l.id === selectedLocation.id)) {
			setNewLocations([...newLocations, selectedLocation]);
		}
		setSelectedLocation(undefined);
	}, [newLocations, selectedLocation]);

	const handleSubmit = useCallback(async () => {
		try {
			await requestLocationsApproval(
				newLocations.map(l => {
					return l.id;
				}),
			);
			if (!locations.isLoading && !locations.error) void locations.refresh();
		} finally {
			setNewLocations([]);
		}
	}, [locations, newLocations, requestLocationsApproval]);

	const handleLocationAdd = useCallback(
		(locationId: LocationId) => setNewLocations(newLocations.filter(x => x.id !== locationId)),
		[newLocations],
	);

	if (locations.isLoading) {
		return <LoadingOverlay />;
	}

	if (locations.error) {
		return <Text>{t('locationAssociations.loadingError')}</Text>;
	}

	return (
		<Stack>
			<Group grow>
				<Title order={3}>{t('locationAssociations.title')}</Title>
				<Group>
					<Box flex={1}>
						<LocationAutocomplete
							excludeLocations={locationIds}
							selectedLocation={selectedLocation}
							onLocationSelected={setSelectedLocation}
						/>
					</Box>
					<Button
						leftSection={<icons.Expand c='var(--mantine-primary-color-contrast)' />}
						disabled={selectedLocation === undefined}
						onClick={addNewLocation}
					>
						{t('locationAssociations.addLocation')}
					</Button>
				</Group>
			</Group>
			<LocationAssociationsList
				assignedLocations={locationAccess.locations}
				requestedLocations={locationAccess.requests}
				newLocations={newLocations}
				locations={locations.locations}
				deleteLocation={deleteLocation}
				addLocation={handleLocationAdd}
			/>
			<Group justify='space-between'>
				<Text>{t('locationAssociations.newLocationApprovalsMessage')}</Text>
				<Button disabled={newLocations.length === 0} onClick={() => void handleSubmit()}>
					{t('locationAssociations.submitForApproval')}
				</Button>
			</Group>
		</Stack>
	);
}
