import { useQuery } from '@tanstack/react-query';

import {
	getActiveAnnouncementsQueryOptions,
	getUserAnnouncementsQueryOptions,
} from '../queries/announcement';

export function useUserAnnouncements() {
	return useQuery(getUserAnnouncementsQueryOptions);
}

export function useUserActiveAnnouncements() {
	return useQuery(getActiveAnnouncementsQueryOptions());
}
