import { queryOptions } from '@tanstack/react-query';

import {
	getAllSupportedLanguageOptions,
	getCountriesWithCultures,
	getCountryOptions,
	getLanguageOptions,
	getSupportedCurrencies,
	getSupportedCurrencyOptions,
	getTimeZoneOptions,
} from './api';

export const globalizationQueryKeys = {
	all: ['globalization'],
	timeZones: ['globalization', 'time-zones'],
	countries: ['globalization', 'countries'],
	countriesWithCultures: (checkForPlantEnabled: boolean = false) =>
		['globalization', 'countries-with-cultures', checkForPlantEnabled] as const,
	supportedCurrencies: ['globalization', 'currencies'],
	globalizationCurrencies: ['globalization', 'currencies', 'options'],
	languages: ['globalization', 'languages'],
	allSupportedLanguages: ['globalization', 'all-supported-languages'],
} as const;

export const globalizationQueryOptions = {
	timeZones: queryOptions({
		queryKey: globalizationQueryKeys.timeZones,
		staleTime: Infinity,
		queryFn: () => getTimeZoneOptions(),
	}),
	countries: queryOptions({
		queryKey: globalizationQueryKeys.countries,
		staleTime: Infinity,
		queryFn: () => getCountryOptions(),
	}),
	globalizationCurrencies: queryOptions({
		queryKey: globalizationQueryKeys.globalizationCurrencies,
		staleTime: Infinity,
		queryFn: () => getSupportedCurrencyOptions(),
	}),
	supportedCurrencies: queryOptions({
		queryKey: globalizationQueryKeys.supportedCurrencies,
		staleTime: Infinity,
		queryFn: () => getSupportedCurrencies(),
	}),
	languages: queryOptions({
		queryKey: globalizationQueryKeys.languages,
		staleTime: Infinity,
		queryFn: () => getLanguageOptions(),
	}),
	allSupportedLanguages: queryOptions({
		queryKey: globalizationQueryKeys.allSupportedLanguages,
		staleTime: Infinity,
		queryFn: () => getAllSupportedLanguageOptions(),
	}),
	countriesWithCultures: (checkForPlantEnabled: boolean = false) =>
		queryOptions({
			queryKey: globalizationQueryKeys.countriesWithCultures(checkForPlantEnabled),
			queryFn: () => getCountriesWithCultures(checkForPlantEnabled),
			staleTime: Infinity,
		}),
};
