import { z } from 'zod';
import type { TFunction } from 'i18next';

import { createExcelErrorSchema, createExcelResultSchema } from '@apple/utils/files';
import {
	countryCodeSchema,
	dateTimeSchema,
	emptyOrNullableLocalDateTimeSchema,
} from '@apple/utils/globalization';
import type {
	UserAllAssociation,
	UserAssociation,
	UserCountryAssociation,
	UserPlantAssociation,
} from '@apple/features/user/models/associations';
import type { ExcelError, ExcelResult } from '@apple/utils/files';
import type { CountryCode } from '@apple/utils/globalization';

export type UserAssociationType = 'all' | 'country' | 'program' | 'plant' | 'subprogram';

/** @see AppleBrandedPrograms.Profile.User */
export interface User {
	[key: string]: unknown;
	id: number;
	username: string;
	firstName: string;
	lastName: string;
	email: string;
	activeDate: '' | Date | null;
	inactiveDate: '' | Date | null;
	lastSignIn?: Date | null;
	lastOrderDate?: Date | null;
	deleted?: boolean;
	numberFormatCountry: CountryCode | null;
	culture: string;
	currencyIsoCode: string | null;
	orderValueLimit: number | null;
	isLocked: boolean;

	userRoles: UserRole[];
	allAssociation: UserAllAssociation | null;
	plantAssociations: UserPlantAssociation[];
	countryAssociations: UserCountryAssociation[];
	programAssociations: UserProgramAssociation[];
	customerAssociations: UserCustomerAssociation[];
	subProgramAssociations: UserSubProgramAssociation[];

	locationAssociations: UserLocationAssociation[];
}

export interface ExtendedUser extends Partial<Omit<User, 'currencyIsoCode'>> {
	currencyIsoCode: string;
}

/** @see AppleBrandedPrograms.Profile.UserProgramAssociation */
export interface UserProgramAssociation extends UserAssociation {
	customerGroupId: number;
}

/** @see AppleBrandedPrograms.Profile.UserSubProgramAssociation */
export interface UserSubProgramAssociation extends UserProgramAssociation {
	countryCode: string;
}

/** @see AppleBrandedPrograms.Profile.UserCustomerAssociation */
export interface UserCustomerAssociation extends UserAssociation {
	customerId: number;
}

/** @see AppleBrandedPrograms.Profile.UserLocationAssociation */
export interface UserLocationAssociation extends UserAssociation {
	locationId: string;
}

/** @see AppleBrandedPrograms.Profile.UserProfile */
export interface UserRole {
	userId: number;
	roleId: number;
	updatedDate?: Date;
}

export const userAssociationSchema = z.object({
	userId: z.number(),
	updatedDate: dateTimeSchema.optional(),
	isDeleted: z.boolean().catch(false),
});

/** @see AppleBrandedPrograms.Profile.UserAllAssociation */
export const userAllAssociationSchema = userAssociationSchema as z.ZodType<UserAllAssociation>;

/** @see AppleBrandedPrograms.Profile.UserPlantAssociation */
export const userPlantAssociationSchema = userAssociationSchema.extend({
	plantId: z.number(),
}) as z.ZodType<UserPlantAssociation>;

/** @see AppleBrandedPrograms.Profile.UserCountryAssociation */
export const userCountryAssociationSchema = userAssociationSchema.extend({
	countryCode: countryCodeSchema,
}) as z.ZodType<UserCountryAssociation>;

/** @see AppleBrandedPrograms.Profile.UserProgramAssociation */
export const userProgramAssociationSchema = userAssociationSchema.extend({
	customerGroupId: z.number(),
}) as z.ZodType<UserProgramAssociation>;

/** @see AppleBrandedPrograms.Profile.UserSubProgramAssociation */
export const userSubProgramAssociationSchema = userAssociationSchema.extend({
	customerGroupId: z.number(),
	countryCode: z.string(),
}) as z.ZodType<UserSubProgramAssociation>;

/** @see AppleBrandedPrograms.Profile.UserCustomerAssociation */
export const userCustomerAssociationSchema = userAssociationSchema.extend({
	customerId: z.number(),
}) as z.ZodType<UserCustomerAssociation>;

/** @see AppleBrandedPrograms.Profile.UserLocationAssociation */
export const userLocationAssociationSchema = userAssociationSchema.extend({
	locationId: z.string(),
}) as z.ZodType<UserLocationAssociation>;

/** @see AppleBrandedPrograms.Profile.UserProfile */
export const userRoleSchema = z.object({
	userId: z.number(),
	roleId: z.number(),
	updatedDate: dateTimeSchema.optional(),
}) as z.ZodType<UserRole>;

/** @see AppleBrandedPrograms.Profile.User */
export const userShape = z.object({
	id: z.number(),
	username: z.string(),
	firstName: z.string(),
	lastName: z.string(),
	email: z.string().email(),
	activeDate: emptyOrNullableLocalDateTimeSchema,
	inactiveDate: emptyOrNullableLocalDateTimeSchema,
	lastSignIn: dateTimeSchema.nullable().optional(),
	lastOrderDate: dateTimeSchema.nullable().optional(),
	deleted: z.boolean().optional(),
	numberFormatCountry: z.string().nullable(),
	culture: z.string(),
	currencyIsoCode: z.string().nullable(),
	orderValueLimit: z.number().nullable().catch(null),
	isLocked: z.boolean().optional(),
	userRoles: z.array(userRoleSchema),
	allAssociation: userAllAssociationSchema.nullable(),
	plantAssociations: z.array(userPlantAssociationSchema),
	countryAssociations: z.array(userCountryAssociationSchema),
	programAssociations: z.array(userProgramAssociationSchema),
	customerAssociations: z.array(userCustomerAssociationSchema),
	subProgramAssociations: z.array(userSubProgramAssociationSchema),
	locationAssociations: z.array(userLocationAssociationSchema),
});

/** @see AppleBrandedPrograms.Profile.User */
export const userSchema = userShape as z.ZodType<User>;

export function extendedUserSchema(t: TFunction<'zod', undefined>) {
	return userShape.partial().extend({
		currencyIsoCode: z.string().min(1, t('zod:errors.Validation_required')),
		userRoles: z.array(userRoleSchema).min(1),
		culture: z.string().min(1, t('zod:errors.Validation_required')),
		numberFormatCountry: z.string({ message: t('zod:errors.Validation_required') }),
	}) satisfies z.ZodType<ExtendedUser>;
}

export type UserExcelResult = ExcelResult<User, 'username', UserExcelResultError>;
export type UserExcelResultError = ExcelError<User, 'username'>;

const excelUserKeySchema = z.object({ username: z.string() });

export const userExcelErrorSchema = createExcelErrorSchema(
	excelUserKeySchema,
) satisfies z.ZodType<UserExcelResultError>;

export const userExcelResultSchema = createExcelResultSchema(
	userSchema,
	userExcelErrorSchema,
	excelUserKeySchema.shape.username,
) satisfies z.ZodType<UserExcelResult>;
